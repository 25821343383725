@use "sass:map";
@use "../functions/breakpoints";
@use "../functions/truncate";
@use "../variables";

$truncations: map.merge(variables.$font-sizes, variables.$h-props);

@each $tag, $ts in $truncations {
  @for $i from 1 through 5 {
    .truncate-#{if($tag == "default", "", "#{$tag}-")}#{$i} {
      @each $bp, $attrs in $ts {
        @include breakpoints.bp-up($bp) {
          @include truncate.truncate-lines($i);
        }
      }
    }
  }
}
