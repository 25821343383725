@use "../variables";

@mixin bl-gradient($direction) {
  background: linear-gradient($direction, variables.color("blue", 700), variables.color("lime", 700));
}

.bl-gradient-horizontal {
  @include bl-gradient(90deg);
}

.bl-gradient-vertical {
  @include bl-gradient(to bottom);
}
