@use "variables";

@use "sass-mq/mq" as * with (
  $breakpoints: variables.$mq-breakpoints,
  $show-breakpoints: variables.$mq-show-breakpoints
);
@forward "sass-mq/mq";

body::before {
  border: 1px solid #dfaf53;
  border-bottom: 0;
  border-right: 0;
  bottom: 0;
  color: var(--gray-800);
  position: fixed;
  top: unset;
  z-index: deep-map-get(variables.$z-indexes, above-all);
}
