@use "../functions/text";
@use "../typography/fonts";
@use "../utilities/borders";
@use "../mq";

%blockquote {
  $blockquote-color: var(--accent-2-700);
  @extend .accent-border-left;
  @extend %font-sans-italic-400;
  color: $blockquote-color;
  margin: 1.5rem 0;
  padding-left: 1rem;
  @include text.text-sizing(1.25rem, 1.75rem);
  @include mq.mq($from: md) {
    @include text.text-sizing(1.5rem, 2rem);
  }
  p {
    margin-bottom: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }
}

blockquote {
  @extend %blockquote;
}
