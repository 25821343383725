@use "../functions/gradient";
@use "../functions/overflow";
@use "../functions/text";
@use "../typography/a";
@use "../typography/fonts";
@use "../typography/h";
@use "../utilities/borders";
@use "../variables";
@use "../mq";

$sub-nav-height: 3.25rem;
$snap-gradient-width: 1.5rem;
$snap-default-btn-width: 1.75rem;
$snap-nav-btn-width: 3rem;

$tab-border-width: 0.125rem;
$button-border-width: 0.25rem;

$icon-button-z-index: deep-map-get(variables.$z-indexes, icon);

@mixin sm-tab-styles {
  border-bottom: $button-border-width solid transparent;
  border-left: none;
  border-right: none;
  margin-bottom: 0;
  padding-bottom: ((text-height(xs, large, variables.$font-sizes) - font-size(md, large, variables.$font-sizes)) * 0.5) + $button-border-width;
  padding-left: 0;
  transform: translateY(calc($button-border-width / 2));
  white-space: nowrap;

  &:focus,
  &:hover {
    border-bottom-color: var(--accent-1-500);
  }
  &:active,
  &.current {
    border-bottom: $button-border-width solid variables.color("black");
  }
}

.tab-row-container {
  margin-bottom: 1.5rem;
  overflow: hidden;
  position: relative;
  width: 100%;

  &.horizontal {
    @include mq.mq($from: md) {
      margin-bottom: 2rem;
    }
  }

  &.vertical {
    height: 100%;
    margin-bottom: 0;
  }

  &.default,
  &.mini {
    .snap-nav-button {
      width: $snap-default-btn-width;
      &.chev-left {
        &::after {
          height: 100%;
          @include gradient.gradient-directional(variables.color("gray", 100), rgba(0,0,0,0), 90deg);
        }
      }
      &.chev-right {
        transform: translate(-$snap-default-btn-width, 0);
        &::before{
          height: 100%;
          @include gradient.gradient-directional(variables.color("gray", 100), rgba(0,0,0,0), -90deg);
        }
      }

      .btn {
        background-color: variables.color("gray", 100);
        height: 100%;
        width: $snap-default-btn-width;

        &:focus svg,
        &:hover svg {
          color: var(--accent-1-500);
        }

        svg {
          color: var(--accent-1-700);
          height: 1.5rem;
          width: 1.5rem;
        }
      }
    }
  }

  &.login {
    .tab-row{
      border-left: none;
      overflow: visible;

      .tab-container {
        margin: 0;
        width: 50%;
      }

      a,
      button {
        align-items: center;
        border-bottom: none;
        border-right: none;
        display: flex;
        justify-content: center;
        margin: 0;
        width: 100%;
        @include sm-tab-styles;

        &:active,
        &.current {
          border-bottom: none;
        }
      }
    }
  }

  &.nav {
    background-color: variables.color("gray", 700);
    border-radius: 0.25rem;
    margin-bottom: 1rem;

    @include mq.mq($from: sm) {
      height: $sub-nav-height;
    }

    .tab-row {
      border-bottom: none;
      border-left: none;
      height: 100%;
      margin-bottom: 0;

      .tabs-container {
        border-bottom: none;
      }

      .tab-container {
        border-bottom:  0.125rem solid variables.color("gray", 900);
        margin-bottom: 0;
        &:last-of-type {
          border-bottom: none;
        }
        @include mq.mq($from: sm) {
          border-bottom: none;
          border-right: 0.125rem solid variables.color("gray", 900);
          transform: none;
        }
      }

      a,
      button {
        @extend %font-sans-normal-500;
        border-bottom: none;
        border-left: 0.5rem solid variables.color("gray", 600);
        border-right: none;
        border-top: none;
        color: variables.color("gray", 300);
        height: 3rem;
        margin-right: 0;
        padding: 0 1rem;
        width: 100%;
        @include text.text-sizing(1.125rem, 1.5rem);

        &.current {
          background-color: variables.color("black");
          border-color: variables.color("blue", 700);
          color: variables.color("white"); // stylelint-disable-line declaration-property-value-disallowed-list

          // stylelint-disable-next-line max-nesting-depth
          &:focus-visible,
          &:hover {
            border-color: variables.color("blue", 700);
          }
          // stylelint-disable-next-line max-nesting-depth
          &:active {
            border-color: variables.color("gray", 600);
          }
        }

        // stylelint-disable-next-line order/order
        &:focus-visible,
        &:hover {
          background-color: variables.color("blue", 700);
          border-color: variables.color("black");
          color: variables.color("black");
          outline: none;
        }

        &:hover,
        &:active {
          color: variables.color("white"); // stylelint-disable-line declaration-property-value-disallowed-list
        }

        &:active {
          background-color: variables.color("black");
          border-color: variables.color("gray", 600);
        }

        @include mq.mq($from: sm) {
          border-bottom: 0.25rem solid variables.color("gray", 600);
          border-left: none;
          border-left-width: 0;
          height: 100%;
          transform: none;
        }
      }
    }

    .snap-nav-button {
      width: $snap-nav-btn-width;
      &.chev-left {
        &::after {
          height: $sub-nav-height;
          @include gradient.gradient-directional(variables.color("gray", 700), rgba(0,0,0,0), 90deg);
        }
      }
      &.chev-right {
        transform: translate(-$snap-nav-btn-width, 0);
        &::before{
          height: $sub-nav-height;
          @include gradient.gradient-directional(variables.color("gray", 700), rgba(0,0,0,0), -90deg);
        }
      }

      .btn {
        background-color: variables.color("gray", 700);
        height: $sub-nav-height;
        width: $snap-nav-btn-width;

        &:focus svg,
        &:hover svg {
          color: variables.color("white"); // stylelint-disable-line declaration-property-value-disallowed-list
        }

        svg {
          color: variables.color("gray", 300);
          height: 2rem;
          width: 2rem;
        }
      }
    }
  }

  &.mini,
  &.login {
    .tab-row.animated {
      .tabs-container {
        border-bottom:  borders.$border-200;
        flex-direction: row;
      }
      .tab-indicator {
        height: $button-border-width;
        top: calc(100% - ($button-border-width / 2));
        transform: translateY(-50%);
        transform-origin: left;
        width: 0.0625rem;
      }
      .tab-container {
        transform: translateY(calc($button-border-width / 2));
      }
      a,
      button {
        &::after {
          height: $button-border-width;
          left: 0;
          top: calc(100% - ($button-border-width));
          transform: translateY(-50%);
          width: 100%;
        }
      }
    }
  }

  &.mini {
    margin-bottom: 2rem;
    .tab-row {
      border-left: none;
      flex-direction: row;
      .tab-container {
        margin-bottom: 0;
      }
      a,
      button {
        @extend %h4;
        align-items: center;
        border-bottom: none;
        color: variables.color(link, color);
        padding-left: 0;
        transform: none;
        @include sm-tab-styles;

        &:focus,
        &:hover {
          color: variables.color(link, hover, color);
        }
        &:active,
        &.current {
          border-bottom: none;
          color: variables.color("black");
        }
      }
    }
    .tab-row.animated {
      a,
      button {
        &::after {
          transform: none;
        }
      }
    }
  }

  .snap-nav-button {
    display: none;
    flex-direction: row;
    position: absolute;
    width: $snap-default-btn-width;
    z-index: $icon-button-z-index;

    &.chev-right {
      left: 100%;
      top: 0;

      &::before{
        content: "";
        position: absolute;
        right: 100%;
        width: $snap-gradient-width;
      }
    }

    &.chev-left {
      left: 0;
      top: 0;

      &::after {
        content: "";
        left: 100%;
        position: absolute;
        width: $snap-gradient-width;
      }
    }

    @include mq.mq($from: sm) {
      display: flex;
    }

    .btn {
      align-items: center;
      border-radius: 0;
      box-shadow: none;
      display: flex;
      justify-content: center;
      margin: 0;
      padding: 0;

      &:hover {
        transform: none;
      }

      svg {
        margin: 0;
      }
    }
  }
}


// hide scrollbar Chrome/Safari/Opera(selector required) -PD
.tab-row::-webkit-scrollbar {
  display: none;
}

.tab-row {
  border-left: borders.$border-200;
  display: flex;
  flex-direction: column;
  line-height: 1.5rem;
  position: relative;
  scrollbar-width: none; // Hide scrollbar - FF only
  width: 100%;
  @include overflow.overflow(visible);

  &.horizontal {
    border-bottom: borders.$border-200;
    border-left: none;
    flex-direction: row;
    @include overflow.overflow(scroll, x);
    @include overflow.overflow(visible, y);

    &.animated {
      border-bottom: none;
      button {
        align-items: center;
        padding-left: 0;
      }
      a,
      button {
        &::after {
          height: $button-border-width;
          left: 0;
          top: calc(100% - ($button-border-width / 2));
          transform: translateY(-50%);
          width: 100%;
        }
      }

      .tab-indicator {
        height: $button-border-width;
        top: calc(100% - ($button-border-width / 2));
        transform: translateY(-50%);
        transform-origin: left;
        width: 0.0625rem;
      }
      .tab .tab-content {
        align-items: center;
        text-align: center;
      }
    }

    a,
    button {
      line-height: 1.75rem;
      @include sm-tab-styles;
    }
    .tab-container {
      margin-bottom: 0;
      transform: translateY(calc($button-border-width / 2));
    }
    .tabs-container {
      border-bottom: borders.$border-200;
      flex-direction: row;
    }
  }

  &.vertical {
    height: 100%;
  }

  &.vertical-left {
    border-bottom-width: 0;
    flex-direction: column;
    margin: 0;
    @include overflow.overflow(visible);

    @include mq.mq($from: sm) {
      border-left: borders.$border-200;
    }

    @include mq.mq($from: md) {
      height: 100%;
    }

    a,
    button {
      border-bottom: none;
      border-bottom-width: 0;
      border-left: $button-border-width solid transparent;
      border-top: none;
      display: block;
      margin-left: -$tab-border-width;
      padding-bottom: 0;
      padding-left: 1rem;
      text-align: left;

      &:active,
      &:focus,
      &.current {
        border-bottom: none;
        border-left-color: variables.color("black");
      }

      @include mq.mq($from: md) {
        padding-left: 1.5rem + $tab-border-width;
        white-space: unset;
      }
    }
  }

  &.animated {
    .tab-container {
      margin-right: 1.5rem;
    }
    a,
    button {
      align-items: flex-start;
      border: none;
      border-bottom: none;
      display: inline-flex;
      flex-direction: column;
      margin-right: 0;
      padding-left: 1rem;
      position: relative;
      transform: none;

      &:active,
      &.current {
        border-bottom: none;
      }

      &:active:focus::after,
      &:active:hover::after {
        opacity: 0;
      }

      &::before {
        @extend %font-sans-normal-700;
        content: attr(data-text);
        height: 0;
        overflow: hidden;
        pointer-events: none;
        user-select: none;
        visibility: hidden;
        @media speech {
          display: none;
        }
      }

      &::after {
        background: var(--accent-1-500);
        content: "";
        height: 100%;
        opacity: 0;
        position: absolute;
        right: 100%;
        top: 0;
        transform: translateX(50%);
        width: $button-border-width;
        z-index: $icon-button-z-index - 1;
      }
      &:focus::after,
      &:hover::after {
        opacity: 1;
      }
      &.current:focus::after,
      &.current:hover::after {
        opacity:0;
      }
    }

    .tab {
      &:focus-visible {
        &::before {
          opacity: 0;
        }
        &::after {
          opacity: 0;
        }
        .tab-content {
          @extend %anchor-focus-styles;
          margin-bottom: 0.25rem;
        }
      }
      .tab-content {
        text-align: left;
      }
    }

    .tab-indicator {
      background: variables.color("black");
      height: 0.0625rem;
      left: 0;
      position: absolute;
      top: 0;
      transform: translateX(-50%);
      transform-origin: top;
      width: $button-border-width;
      will-change: transform;
      z-index: $icon-button-z-index - 1;
    }
  }

  .tab-container {
    display: flex;
    margin-bottom: 1rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .tabs-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  // If making changes to height/spacing/borders, be sure to update the manage-data-anchor in debt data
  // (see web/issuerportal/assets/stylesheets/actions/_debt-data.scss, web/issuerportal/assets/scripts/actions/debt-data/actions/series-data/SeriesData.tsx)
  a,
  button {
    @extend %font-sans-normal-400;
    @extend %font-size-large;
    align-items: center;
    border-bottom: none;
    border-left-style: solid;
    border-left-width: $button-border-width;
    display: flex;
    line-height: 1.5rem;
    margin-right: 1.5rem;
    padding-bottom: 0;
    padding-left: 1rem;

    &:active,
    &.current {
      @extend %font-sans-normal-700;
      border-left-color: variables.color("black");
      color: variables.color("black");
    }

    svg {
      margin-right: 0.25rem;
    }
  }
}
