@use "../functions/overflow";
@use "../functions/transitions";
@use "../functions/text";
@use "../typography/fonts";
@use "../typography/list";
@use "../utilities/borders";
@use "../utilities/shadows";
@use "../variables";
@use "../mq";

$input-border: variables.$input-border-width solid variables.$input-border-color;

%input-border {
  border: $input-border;
}

%input-disabled {
  background-color: variables.$input-disabled-bg;
  border-color: variables.$input-border-color;
  color: variables.$input-disabled-color;
}

label {
  @extend %font-sans-normal-500;
  color: variables.color("black");
  display: block;
  margin-bottom: variables.$label-margin-bottom;
  @include text.text-sizing(font-size(xs, default, variables.$font-sizes), line-height(xs, default, variables.$font-sizes));
}

input,
textarea,
.custom-select {
  @extend %box-shadow-1;
  @extend %input-border;

  background-color: variables.color("white");
  border-radius: variables.$input-border-radius;
  color: variables.color("black");
  display: block;
  padding: variables.$input-padding-xs;
  width: 100%;

  @include text.text-sizing(variables.$input-xs-font-size, variables.$input-xs-line-height-with-rem);

  &:active {
    border-color: variables.$input-active-border-color;
    outline: 0;
  }

  &:focus,
  &.focus {
    @extend %focus-shadow;
  }

  &[disabled] {
    @extend %input-disabled;
  }

  @include mq.mq($from: md) {
    padding: variables.$input-padding-md;
  }

  @include mq.mq($from: md) {
    @include text.text-sizing(variables.$input-md-font-size, variables.$input-md-line-height-with-rem);
  }
}

textarea {
  height: ((variables.$input-border-width + variables.$input-padding-xs) * 2) + (variables.$input-xs-font-size * variables.$input-xs-line-height * 3);

  @include mq.mq($from: md) {
    height: ((variables.$input-border-width + variables.$input-padding-md) * 2) + (variables.$input-md-font-size * variables.$input-md-line-height * 3);
  }
}

$custom-indicator-size: 1.5rem;
$indicator-margin: 0.5rem;
$custom-indicator-label-indent: $custom-indicator-size + $indicator-margin;

.note-text {
  @extend %font-sans-italic-400;
  color: variables.color("gray", 600);
  line-height: 1.5rem;
}


select.custom-select {
  display: none;
}

.custom-select {
  @extend %border-radius-1;

  appearance: none;

  .icon {
    color: variables.color(link, color);
    fill: variables.color(link, color);
    float: right;
    transition: transform variables.$transition-base-duration ease;
  }
}

.custom-select-wrapper {
  position: relative;

  &.show {
    .custom-select {
      svg {
        transform: rotate(-180deg);
      }
    }
    .custom-select-options {
      display: block;
    }
  }
}

.checkbox-group {
  @extend %transition-base;

  max-height: 0;
  overflow: hidden;
}

$custom-select-option-padding-y: 0.5rem;
$custom-select-option-padding-x: 0.75rem;

@function custom-select-height($bp) {
  @return 8 * (line-height($bp, default, variables.$font-sizes) + (2 * $custom-select-option-padding-y));
}

%custom-option-hover {
  background-color: var(--accent-1-100);
  color: var(--accent-1-900);
  cursor: pointer;
}

.custom-select-options {
  @extend %border-radius-1;
  @extend %box-shadow-2;
  @extend .list-unstyled;

  background-color: variables.color("white");
  display: none;
  left: 0;
  max-height: custom-select-height(xs);
  position: absolute;
  width: 100%;
  z-index: deep-map-get(variables.$z-indexes, dropdown);
  @include overflow.overflow(scroll, y);

  @include mq.mq($from: md) {
    max-height: custom-select-height(md);
  }

  .custom-option {
    padding: $custom-select-option-padding-y $custom-select-option-padding-x;

    &:hover:not([disabled], [data-disabled]) {
      @extend %custom-option-hover;
    }

    &.selected:not([disabled], [data-disabled]) {
      @extend %font-sans-normal-700;
      background-color: var(--accent-1-700);
      color: variables.color("white"); // stylelint-disable-line declaration-property-value-disallowed-list
    }

    &[disabled],
    &[data-disabled] {
      @extend %input-disabled;
      cursor: not-allowed;
    }
  }
}

form .alert {
  margin-bottom: 0;
  margin-top: 1rem;
}

%form-input-disabled-styles {
  pointer-events: none;

  .icon {
    color: variables.color("gray", 500);
  }

  .indicator {
    border-color: variables.color("gray", 200);
  }

  .icon-label {
    color: variables.color("gray", 300);
  }
}

.form-input {
  $input-prefix-spacing: 0.5rem;
  $input-prefix-icon-size: 1.5rem;
  $input-prefix-icon-top-spacing: $input-prefix-icon-size * 0.5;


  $input-padding-text: $input-prefix-icon-size + $input-prefix-spacing;
  $input-padding-icon: $input-prefix-icon-size + ($input-prefix-spacing * 2);
  margin-bottom: variables.$input-margin-bottom;
  width: 100%;

  &.disabled {
    @extend %form-input-disabled-styles;
  }

  &.input-group {
    display: inline-block;

    .input-group-container {
      @extend %box-shadow-1;
      display: flex;

      &:focus-within {
        @extend %focus-shadow;
        border-radius: variables.$input-border-radius;
      }
    }

    input {
      border-radius: variables.$input-border-radius 0 0 variables.$input-border-radius;
      border-right: none;
      box-shadow: none;
    }

    .input-group-postfix {
      &.has-value {
        .has-value-show {
          display: block;
        }

        .has-value-hide {
          display: none;
        }
      }

      .has-value-show {
        display: none;
      }

      .has-value-hide {
        display: block;
      }

      .btn {
        border-radius: 0 variables.$input-border-radius variables.$input-border-radius 0;
        height: variables.$input-height;
        margin: 0;
        width: variables.$input-height;

        &:hover {
          transform: none;
        }

        @include mq.mq($from: md) {
          height: variables.$input-height-md;
          width: variables.$input-height-md;
        }

        .icon {
          margin: 0;
        }
      }
    }
  }

  &.has-danger {
    label,
    input,
    textarea,
    input ~ .label,
    .tox-edit-area::before,
    .input-prefix .icon,
    .input-postfix .icon,
    .error-text {
      border-color: variables.color(danger, color);
      color: variables.color(danger, color);
    }

    .tox-edit-area::before{
      opacity: 1;
    }

    label,
    .label {
      @extend %font-sans-normal-700;
    }

    .custom-indicator {
      input[type="checkbox"] ~ .option-label,
      input[type="radio"] ~ .option-label {
        @extend %font-sans-normal-400;
      }

      input:checked ~ .option-label {
        @extend %font-sans-normal-500;
      }

      input[type="checkbox"] {
        &:checked ~ .indicator {
          background-color: variables.color(danger, color);
        }

        ~ .indicator {
          border-color: variables.color(danger, color);
        }
      }
    }

    .btn-action {
      border-color: variables.color(danger, color);
    }

    .error-text {
      display: block;
    }
  }

  .readonly-input-button {
    cursor: pointer;
  }


  &-prefix {
    &.text-prefix input {
      padding-left: $input-padding-text;
    }
    &.icon-prefix input {
      padding-left: $input-padding-icon;
    }
  }
  &-postfix {
    &.text-postfix input {
      padding-right: $input-padding-text;
    }
    &.icon-postfix input {
      padding-right: $input-padding-icon;
    }
  }

  .input-container-inner {
    position: relative;
  }
  .input-prefix {
    left: $input-prefix-spacing;
  }
  .input-postfix {
    right: $input-prefix-spacing;
  }
  .input-postfix,
  .input-prefix {
    @extend %font-sans-normal-400;
    color: variables.color("gray", 600);
    display: block;
    font-size: 1rem;
    min-width: 1.5rem;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    width: auto;

    &.no-action {
      pointer-events: none;
    }
  }

  .input-icon {
    height: 100%;
    margin-bottom: 0;
    position: relative;
    width: 100%;

    input {
      &::-ms-clear {
        display: none;
      }
    }

    .icon {
      color: variables.color("blue", 700);
      height: 1.75rem;
      position: absolute;
      top: 0.5625rem;
      transform: scale(0.9);
      width: 1.75rem;
      &:first-child {
        left: 0.75rem;
      }
      &:last-child {
        right: 0.75rem;
      }
      &.button {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .date-picker-wrapper .input-icon .icon {
    height: 1.5rem;
    top: 0.7rem;
    width: 1.5rem;
  }
  .error-text {
    display: none;
    margin-top: variables.$error-text-mt;
    @include text.text-sizing(font-size(xs, small, variables.$font-sizes), line-height(xs, small, variables.$font-sizes));

    @at-root .btn-action + .error-text {
      margin-top: -(variables.$btn-callout-action-mb - variables.$error-text-mt);
    }
  }

  .tippy-content {
    .content {
      @extend %font-sans-normal-400;
    }
  }

  .label-container {
    display: flex;
    justify-content: space-between;
    .char-count {
      color: variables.color("gray", 600);
      margin-bottom: 0;
    }
  }
}

.custom-indicator-description {
  @extend .small;
  color: variables.color("gray", 600);
  display: block;
  margin-left: $custom-indicator-label-indent;
}

.custom-indicator {
  cursor: pointer;
  position: relative;
  user-select: none;
  width: fit-content;
  &:not(:first-child) {
    margin-top: 1rem;
  }

  &:hover,
  input:hover ~ {
    .option-label {
      color: variables.color(link, color);
    }
  }

  &.disabled {
    @extend %form-input-disabled-styles;

    input {
      &[type="checkbox"]:checked ~ .indicator,
      &[type="radio"]:checked ~ .indicator::after {
        background-color: variables.color("gray", 500);
      }

      &[type="checkbox"]:checked,
      &[type="radio"]:checked {
        ~ .indicator {
          border-color: variables.color("gray", 500);
        }

        ~ .option-label {
          color: variables.color("gray", 500);
        }
      }

      ~ .indicator {
        background-color: variables.$input-disabled-bg;
        border-color: variables.color("gray", 300);
        &::after {
          border-color: variables.color("gray", 200);
        }
      }

      ~ .option-label,
      ~ .option-label .subtitle, {
        color: variables.color("gray", 500);
      }
    }
  }

  input:hover {
    &[type="checkbox"]:checked ~ .indicator,
    &[type="radio"] ~ .indicator:after {
      background-color: variables.color(link, hover, color);
      border-color: variables.color(link, hover, color);
    }

    ~ .indicator {
      border-color: variables.color(link, hover, color);
    }
  }

  input:focus {
    ~ .indicator {
      @extend %focus-shadow;
    }
  }

  .indicator {
    @extend %box-shadow-1;
    @extend %input-border;
    background-color: variables.color("white");
    height: $custom-indicator-size;
    left: 0;
    position: absolute;
    top: 0;
    width: $custom-indicator-size;

    &::after {
      content: "";
      display: none;
      position: absolute;
    }
  }

  input[type="checkbox"] ~ .indicator {
    border-radius: 0.125rem;

    &::after {
      border: solid variables.color("white");
      border-width: 0 0.1875rem 0.1875rem 0;
      height: 1rem;
      left: 0.375rem;
      transform: rotate(45deg);
      width: 0.5rem;
    }
  }

  input[type="radio"] ~ .indicator {
    border-radius: 0.75rem;

    &::after {
      background-color: variables.color(link, color);
      border-radius: 0.375rem;
      height: 0.75rem;
      left: 0.25rem;
      top: 0.25rem;
      width: 0.75rem;
    }
  }

  .option-label {
    @extend %font-sans-normal-400;
    color: variables.color("gray", 800);
    display: inline-block;
    font-size: variables.$input-xs-font-size;
    line-height: $custom-indicator-size;
    margin-left: $custom-indicator-label-indent;

    @include mq.mq($from: md) {
      font-size: variables.$input-md-font-size;
    }
  }

  input {
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;

    &:checked {
      ~ .indicator {
        border-color: variables.color(link, color);

        &::after {
          display: block;
        }
      }
      ~ .option-label {
        @extend %font-sans-normal-500;
        color: variables.color("black");
      }
    }

    &[type="checkbox"]:checked ~ .indicator {
      background-color: variables.color(link, color);
    }
  }
}

.search {
  &.form-input {
    &.input-group {
      input {
        border-radius: 0.25rem 0 0 0.25rem;
        border-right: none;
        box-shadow: none;
        padding-left: 1rem;
        padding-right: 1rem;
      }

      // override specificity of .form-input.input-group .input-group-postfix .btn .icon
      .input-group-postfix .btn .icon {
        margin: -0.25em 0.25em -0.25em 0;
      }
    }

    // Use border-radius on container to avoid squared corners showing beneath input elements
    .input-group-container {
      border-radius: 0.25rem;
      box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.12);
      display: flex;
      width: 100%;
    }

    .btn {
      background: variables.color("white");
      border-color: variables.color("gray", 300);
      border-left: none;
      border-radius: 0 0.25rem 0.25rem 0;
      color: variables.color("blue", 900);
      height: 100%;
      width: 2.75rem;

      &:hover {
        transform: none;
      }
    }
  }

  .btn-secondary {
    border-left: none;
    box-shadow: none;
    transition: none;

    &:hover {
      border-color: variables.color("gray", 300);
      color: variables.color("blue", 700);
    }

    &:focus {
      @extend %focus-shadow;
    }

    svg {
      fill: currentcolor;
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  input:focus + .input-group-postfix .btn-secondary {
    @extend %focus-shadow;
  }
}

.form-section {
  padding-bottom: 0.5rem;

  @include mq.mq($from: lg) {
    .field-group-container {
      display: flex;
      justify-content: space-between;
    }

    .field-group {
      width: 42rem;
    }

    .section-tip {
      height: 0;
      margin-left: 2rem;
      width: 9rem;
    }
  }

  .form-input-row {
    display: flex;
    flex-direction: column;
    @include mq.mq($from: md) {
      flex-direction: row;
    }
  }

  .field-group {
    .related-content {
      .callouts {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .callout {
          margin-right: 1rem;
        }
      }
      .alert.alert-subtle {
        margin-bottom: 1rem;
        margin-top: 0;
      }
    }
    .attachments,
    .related-list {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      @include mq.mq($from: sm) {
        flex-direction: row;
      }
      .callout-item {
        max-width: 19rem;
        &:nth-of-type(odd) {
          margin-right: 1rem;
        }
      }
    }
  }

  .title-group {
    align-items: baseline;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @include mq.mq($from: md) {
      flex-wrap: nowrap;
      justify-content: flex-start;

      .title {
        margin-bottom: 0;
      }

      .subtitle {
        margin-bottom: 0;
      }
    }

    .title {
      margin: 0 0.5rem 1rem 0;
    }

    .subtitle {
      margin-bottom: 1rem;
    }
  }
}
.date-select {
  display: flex;
  justify-content: space-between;
  > .form-input {
    &:first-of-type {
      flex: 0 0 6.5rem;
      margin-right: 1rem;
    }
    &:last-of-type {
      width: 100%;
    }
  }
}
.form {
  &.disabled {
    pointer-events: none;
    position: relative;
    &:before {
      background: rgba(variables.color("gray", 100), 0.5);
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: deep-map-get(variables.$z-indexes, component-loader);
    }
  }
}
form {
  p.error-text.form-error  {
    color: variables.color(danger, color);
    margin-bottom: 0;
    margin-top: 0.5rem;
  }
}
