@use "sass:map";
@use "../functions/breakpoints";
@use "../variables";

:root {
  --border-100: 0.125rem solid #{variables.color("gray", 100)};
  --border-200: 0.125rem solid #{variables.color("gray", 200)};
  --border-300: 0.125rem solid #{variables.color("gray", 300)};
}
$border-100: var(--border-100);
$border-200: var(--border-200);
$border-300: var(--border-300);


%border-radius-0125 {
  border-radius: variables.$border-radius-0125;
}

.border-radius-0125 {
  @extend %border-radius-0125;
}

%border-radius-1 {
  border-radius: variables.$border-radius-1;
}

.border-radius-1 {
  @extend %border-radius-1;
}

$borders: (
  "0": none,
  "0125": $border-200,
);

@each $bp in map.keys(variables.$breakpoints) {
  @each $class-val, $val in $borders {
    @each $dir, $props in variables.$direction-map {
      @include breakpoints.bp-up($bp) {
        @include breakpoints.bp-class("b#{$dir}", $bp, $class-val) {
          @each $prop-suffix in $props {
            border#{if($prop-suffix == "", "", "-")}#{$prop-suffix}: #{$val} !important;
          }
        }
      }
    }
  }
}

$border-widths: (
  "0": 0,
  "0125": 0.125rem,
  "025": 0.25rem,
);

@each $bp in map.keys(variables.$breakpoints) {
  @each $index in map.keys(deep-map-get(variables.$base-colors, "gray")) {
    @each $class-val, $val in $border-widths {
      @each $dir, $props in variables.$direction-map {
        @include breakpoints.bp-up($bp) {
          @include breakpoints.bp-class("b#{$dir}", $bp, "#{$class-val}-#{$index}") {
            @each $prop-suffix in $props {
              border#{if($prop-suffix == "", "", "-")}#{$prop-suffix}: #{$val} solid #{variables.color("gray", $index)} !important;
            }
          }
        }
      }
    }
  }
}

@function accent-border-props($pos) {
  @return (
    xs: (
      padding-#{$pos}: 0.5rem,
      border-#{$pos}: variables.$accent-2-border,
    ),
    md: (
      padding-#{$pos}: 1rem,
    )
  );
}

/// Adds properties for an "accent border"
/// @param {String} position - "top" | "bottom" | "left" | "right"
/// @output Accent border props at different screen sizes
@mixin accent-border($pos) {
  @include breakpoints.bp-props(accent-border-props($pos));
}

@each $p in (left, top, bottom) {
  .accent-border-#{$p} {
    @include accent-border($p);
  }

  @each $bp, $_props in accent-border-props($p) {
    @include breakpoints.bp-selector(".", "no-accent-border-#{$p}", $bp, "") {
      @include breakpoints.bp-up($bp) {
        @each $prop, $value in deep-map-get(accent-border-props($p), xs) {
          #{$prop}: initial;
        }
      }
    }
  }

  .border-#{$p}-transparent {
    border-#{$p}-color: transparent !important;
  }
}
