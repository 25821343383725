@use "../functions/icon";
@use "../utilities/shadows";
@use "../variables";
@use "../mq";
@use "buttons";

.icon {
  $vertical-diff-em: variables.btn-padding-diff(1em, 1em);
  $vertical-diff-small-em: variables.btn-padding-diff(0.875em, 0.875em);

  height: variables.$icon-default-line-height * 1em; // Define static height for IE, which ignores calc
  height: variables.$icon-base-size;
  vertical-align: top;
  width: variables.$icon-default-line-height * 1em; // Define static height for IE, which ignores calc
  width: variables.$icon-base-size;

  @mixin btn-icon($mult, $vertical-diff, $include-left-margin: true, $is-right: false) {
    $btn-icon-size: 1em * $mult;
    $btn-icon-m: (($btn-icon-size - 1) * 0.5);
    $btn-icon-m-vert: ($btn-icon-m - $vertical-diff);
    height: $btn-icon-size;
    margin: (-$btn-icon-m-vert) $btn-icon-m (-$btn-icon-m-vert) (-$btn-icon-m);
    width: $btn-icon-size;
    @if $include-left-margin == true {
      margin-left: 0;
    }
    @if $is-right == true {
      margin-left: $btn-icon-m;
      margin-right: 0;
    }
  }

  .background {
    color: transparent;
  }

  .btn & {
    @include btn-icon(1.5, $vertical-diff-em);
  }

  .btn.icon-right & {
    @include btn-icon(1.5, $vertical-diff-em, false, true);
  }

  .btn-small & {
    @include btn-icon(1.125, $vertical-diff-small-em);
    @include mq.mq($from: md) {
      @include btn-icon(1.125, $vertical-diff-em);
    }
  }

  @at-root .btn-primary {
    &.btn-silhouette {
      // stylelint-disable order/order
      .icon {
        background: deep-map-get(buttons.$btn-primary, default, color);
        color: deep-map-get(buttons.$btn-primary, default, background);
      }
      &:hover {
        .icon {
          color: deep-map-get(buttons.$btn-primary, hover, background);
        }
      }

      &:focus {
        .icon {
          @extend %focus-drop-shadow;
        }
      }
      &:active .icon {
        color: deep-map-get(buttons.$btn-primary, active, background);
      }
      // stylelint-enable order/order
    }
  }

  .icon-large & {
    @extend %border-radius-1;
    @include btn-icon(2.25, 0, false);
    @include mq.mq($from: md) {
      @include btn-icon(2.5, 0, false);
    }
  }

  .data-points & {
    color: var(--accent-1-700);
  }
}

.icon-watermark {
  color: variables.color("gray", 100);
  filter: drop-shadow(0 0 1rem rgba(variables.color("black"), 0.75));
  height: 5rem;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 5rem;
  z-index: deep-map-get(variables.$z-indexes, icon);
}

.icon-watermark-hover {
  transition: variables.$transition-base;

  &:hover {
    color: variables.color("white"); // stylelint-disable-line declaration-property-value-disallowed-list
    cursor: pointer;
    filter: drop-shadow(0 0 1rem variables.color("black"));
  }
}

.icon-circle {
  @extend %icon-circle;
}
