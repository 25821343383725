@use "../functions/text";
@use "../typography/fonts";
@use "../typography/h";
@use "../utilities/borders";
@use "../variables";
@use "../mq";

$data-list-border-right: borders.$border-200;

dl {
  // Reset Margins Added By Browser
  margin: 0;
  dt {
    @extend %font-sans-normal-700;
  }
}


.data-point-small {
  dd {
    @extend %font-sans-normal-500;
    @extend %font-size-default;
    color: variables.color("black");
  }
}

.data-point-medium {
  dd {
    @extend %font-sans-normal-500;
    color: var(--accent-2-700);
    @include text.text-sizing(1.125rem, 1.5rem);

    @include mq.mq($from: md) {
      @include text.text-sizing(1.25rem, 1.75rem);
    }
  }
}

%data-point-large-text {
  @extend %font-sans-normal-700;
  @include text.text-sizing(2rem, 2.25rem);

  @include mq.mq($from: md) {
    @include text.text-sizing(2.25rem, 2.5rem);
  }
};

.data-point-large-text {
  @extend %data-point-large-text;
}

.data-point-large {
  dd {
    @extend %data-point-large-text;
    color: var(--accent-2-700);
  }
}

@mixin data-horizontal-styles {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0;
  row-gap: 1rem;
  .data-point:not(:last-child) {
    border-right: $data-list-border-right;
  }
}

.data-horizontal {
  @include data-horizontal-styles;
  &.small {
    @extend %font-sans-normal-500;
    dd {
      @include text.text-sizing(1rem, 1.5rem);
    }
    .data-point:not(:last-child) {
      margin-right: 0.75rem;
      padding-right:  0.75rem;
    }
  }

  .data-point:not(:last-child) {
    margin-right: 0.875rem;
    padding-right: 0.875rem;

    @include mq.mq($from: md) {
      margin-right: 1rem;
      padding-right: 1rem;
    }
  }

  .data-point-medium:not(:last-child) {
    border-right: $data-list-border-right;
    margin-right: 1rem;
    padding-right: 1rem;
  }
}

.data-vertical {
  display: grid;
  gap: 0.75rem 2rem;
  grid-template-columns: auto auto;
  width: fit-content;

  dt {
    @extend %font-sans-normal-400;
    align-items: center;
    color: variables.color("gray", 600);
    display: flex;
    @include text.text-sizing(1rem, 1.5rem);
  }
  dd {
    @extend p;
    @extend %font-sans-normal-500;
    margin-bottom: 0 !important;
  }
}

.data-point-row {
  column-gap: 0.5rem;
  display: flex;
  flex-direction: row;
  width: fit-content;
  .data-title {
    width: 12.5rem;
  }
  .data-title,
  .data-value {
    @include text.text-sizing(1rem, 1.5rem);
  }
  dt {
    @extend %font-sans-normal-400;
    align-items: center;
    color: variables.color("gray", 600);
    display: flex;
  }
  dd {
    @extend p;
    @extend %font-sans-normal-500;
    margin-bottom: 0 !important;
  }
}

.data-horizontal,
.data-vertical,
.data-sidebar,
.data-row {
  &.accent,
  &.bold {
    dd,
    button {
      color: var(--accent-2-700) !important;

    }
    button:hover {
      color: var(--accent-2-500) !important;
    }
  }

  &.bold {
    dd,
    button {
      @extend %font-sans-normal-500;
      @include text.text-sizing(1.25rem, 1.75rem);
    }
  }
  &.bold-h2 {
    dt {
      @extend %h4;
    }
    dd {
      color: variables.$chart-blue-summary;
      @include text.text-sizing(2.25rem, 2.5rem);
    }
  }
}

.data-sidebar {
  @include mq.mq($until: md) {
    @include data-horizontal-styles;
    .data-point:not(:last-child) {
      margin-right: 1rem;
      padding-right: 1rem;
    }
  }
  @include mq.mq($from: md) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 1rem;
  }
}

.data-horizontal,
.data-sidebar {
  .data-point,
  .data-point-medium {
    margin-bottom: 0;
  }
}

.data-point,
.data-point-medium {
  margin: 0 0 1rem;
  &:last-child {
    margin-bottom: 0;
  }

  dt {
    @extend %h6;
    margin: 0;
  }

  dd {
    @extend %font-sans-normal-500;
    margin-bottom: 0;
    margin-left: 0;
  }

  p {
    margin: 0;
  }

  .tooltip-trigger {
    font-size: 0.75em;
    margin-left: 0.25rem;
  }
}
