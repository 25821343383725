@use "sass:map";
@use "sass:math";
@use "../variables";
@use "breakpoints";
@use "var";

@mixin text-sizing($font-size, $line-height) {
  $lh: if(math.is-unitless($line-height) == true, $line-height, deep-map-get(text-sizing($font-size, $line-height), line-height));
  font-size: $font-size;

  @include var.prop-var(line-height, $lh);
}

$white-spaces: (normal, nowrap, pre-wrap);

@each $bp in map.keys(variables.$breakpoints) {
  @each $v in $white-spaces {
    @include breakpoints.bp-up($bp) {
      @include breakpoints.bp-class(white-space, $bp, $v) {
        white-space: $v !important;
      }
    }
  }
}
