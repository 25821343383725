@use "sass:list";
@use "sass:map";
@use "../functions/color";
@use "../typography/fonts";
@use "../variables";

:root {
  // WHEN MODIFYING VALUES HERE MAKE SURE TO UPDATE SiteTemplateStyles.tsx
  @each $color, $mapping in variables.$base-colors {
    @each $index, $hex in $mapping {
      --#{$color}-#{$index}: #{$hex};

      .#{$color}-#{$index} {
        color: $hex;
      }
      .#{$color}-#{$index}-bg {
        background-color: $hex;
      }

      .#{$color}-#{$index}-border-color {
        border-color: $hex;
      }
    }
  }

  @each $color in ("black", "white") {
    $hex: deep-map-get(variables.$colors, $color);
    --#{$color}: #{$hex};

    .#{$color} {
      color: $hex;
    }
    .#{$color}-bg {
      background-color: $hex;
    }

    .#{$color}-border-color {
      border-color: $hex;
    }
  }

  @each $c in variables.$accent-colors {
    $index: list.index(variables.$accent-colors, $c);

    $dec-levels: ();
    @each $k, $v in deep-map-get(variables.$base-colors, $c) {
      $dec-levels: map.merge($dec-levels, ($k: color.to-rgb($v))); // stylelint-disable-line scss/no-duplicate-dollar-variables
    }

    @each $k, $v in deep-map-get(variables.$base-colors, $c) {
      --accent-#{$index}-#{$k}: #{$v};

      .accent-#{$index}-#{$k} {
        color: var(--accent-#{$index}-#{$k}) !important;
      }

      .accent-#{$index}-#{$k}-bg {
        background-color: var(--accent-#{$index}-#{$k}) !important;
      }

      .accent-#{$index}-#{$k}-border-color {
        border-color: var(--accent-#{$index}-#{$k}) !important;
      }
    }

    @each $l, $v in $dec-levels {
      --accent-#{$index}-#{$l}-dec: #{$v};
    }
  }
}

.text-danger {
  @extend %font-sans-normal-700;
  color: variables.color(danger, color);
}

.text-success {
  @extend %font-sans-normal-700;
  color: variables.color(success, color);
}
