@use "sass:map";
@use "../functions/breakpoints";
@use "../variables";

@each $bp in map.keys(variables.$breakpoints) {
  @each $d in (left, right, none) {
    @include breakpoints.bp-up($bp) {
      @include breakpoints.bp-class("float", $bp, $d) {
        float: $d !important;
      }
    }
  }
}

@each $d in (left, right, both) {
  .clear-#{$d} {
    clear: $d !important;
  }
}


@each $bp in map.keys(variables.$breakpoints) {
  @include breakpoints.bp-up($bp) {
    @include breakpoints.bp-class("clear-fix", $bp, "") {
      &::after {
        clear: both;
        content: "";
        display: table;
      }
    }
  }
}
