@use "sass:list";
@use "sass:string";
@use "../variables";
@use "../mq";
@use "breakpoints";
@use "inverted";
@use "types";
@use "../typography/fonts";
@use "../utilities/shadows";

@mixin h($h) {
  $h-level: types.to-number(string.slice($h, 2, 2));

  @if $h-level < 1 or $h-level > 6 {
    @error "H level: `#{$h}` must be between 1 and 6";
  }

  %#{$h},
  #{$h},
  .#{$h} {
    @content;
  }
}

@mixin h-list($h-props) {
  @each $h in $h-props {
    @include h($h) {
      @content;
    }
  }
}

@mixin h-props($h-props) {
  @each $h, $sizes in $h-props {
    @include h($h) {
      @each $bp, $props in $sizes {
        @include breakpoints.bp-up($bp) {
          @each $prop, $value in $props {
            @if($prop == extend) {
              @each $v in $value {
                @extend #{string.unquote($v)};
              }
            } @else if(list.index((line-height), $prop) != null) {
              --#{$prop}: #{$value};
              #{$prop}: #{$value};
            } @else {
              #{$prop}: #{$value};
            }
          }
        }
      }

      @include inverted.inverted {
        color: variables.color("white"); // stylelint-disable-line declaration-property-value-disallowed-list

        @if $h == h1 or $h == h3 {
          @extend %text-shadow-2;

          @include mq.mq($from: md) {
            @extend %text-shadow-md-4;
          }
        }
      }
    }
  }
}
