@use "sass:map";
@use "../functions/breakpoints";
@use "../variables";

$displays: (block, flex, grid, inline, inline-block, inline-flex, none, table, table-cell);

@each $bp in map.keys(variables.$breakpoints) {
  @each $disp in $displays {
    @include breakpoints.bp-up($bp) {
      @include breakpoints.bp-class(d, $bp, $disp) {
        display: $disp !important;
      }
    }
  }
}

@each $bp in map.keys(variables.$breakpoints) {
  @include breakpoints.bp-down($bp){
    @include breakpoints.bp-class(d-none-until, $bp, ""){
      display: none !important;
    }
  }
}

.v-hidden {
  visibility: hidden;
}
