@use "fonts";

$list-indentation: 1rem;
$list-indicator-pr: 0.625rem;
$list-mb: 0.5rem;

ul,
ol {
  margin-bottom: 1rem;
  margin-left: 1.5rem;

  & & {
    margin-bottom: 0;
    margin-left: 0;
    margin-top: $list-mb;
  }

  li {
    margin-bottom: $list-mb;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

ul {
  padding-left: $list-indentation * 2;

  li {
    margin-left: -$list-indentation;
    padding-left: $list-indicator-pr;
  }
}

ol {
  counter-reset: item;
  list-style-type: none;
  padding-left: 0;

  li {
    counter-increment: item;
    display: table;

    &::before {
      @extend %font-sans-normal-700;
      content: counters(item, ".") ". ";
      display: table-cell;
      min-width: $list-indentation;
      padding-right: $list-indicator-pr;
      white-space: nowrap;
    }
  }
}

.list-unstyled {
  list-style: none;
  margin-left: 0;
  padding-left: 0;

  li {
    margin-left: 0;
    padding-left: 0;
  }
}
