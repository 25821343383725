@use "../variables";

@mixin overlay-opacity($base-val, $show-val) {
  opacity: $base-val;

  &.showing {
    opacity: $show-val;
  }

  &.show {
    opacity: $show-val;

    &.hiding {
      opacity: $base-val;
    }
  }
}

%overlay {
  bottom: 0;
  display: none;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;

  &.showing,
  &.show,
  &.hiding {
    display: block;
  }
}

@mixin overlay($base-opacity, $show-opacity, $z-index, $bg: variables.color("black")) {
  @extend %overlay;
  background: $bg;
  z-index: deep-map-get(variables.$z-indexes, $z-index);
  @include overlay-opacity($base-opacity, $show-opacity);
}
