@use "sass:list";
@use "sass:map";
@use "../functions/breakpoints";
@use "../functions/inverted";
@use "../functions/text";
@use "../typography/fonts";
@use "../variables";
@use "../mq";

$icon-link-icon-spacing: 0.25em;

@mixin anchor-state-styles($color, $hover-color, $active-color, $border-placement: bottom, $selector: "") {
  $border-sides: (top, right, bottom, left);
  @if not (list.index($border-sides, $border-placement)) {
    @error "`#{$border-placement}` must be one of `#{$border-sides}`";
  }

  color: $color;
  cursor: pointer;

  &.always-decorate {
    border-#{$border-placement}-color: $color;
  }

  // stylelint-disable order/order
  &:hover, &:hover #{$selector} {
    border-#{$border-placement}-color: $hover-color;
    color: $hover-color;
  }

  &:active, &:active #{$selector} {
    border-#{$border-placement}-color: $active-color;
    color: $active-color;
    opacity: 0.8;
  }
  // stylelint-enable order/order
}

.anchor-state-styles-black {
  @include anchor-state-styles(variables.color("black"), variables.color("gray", 800), variables.color("gray", 700));
}

@each $bp in map.keys(variables.$breakpoints) {
  @include breakpoints.bp-up($bp) {
    @include breakpoints.bp-selector("%", anchor-state-styles-white, $bp, "") {
      @include anchor-state-styles(variables.color("white"), variables.color("white"), variables.color("white"));
    }
  }
}

%anchor-state-styles {
  @include anchor-state-styles(variables.color(link, color), variables.color(link, hover, color), variables.color(link, active, color));
}

%anchor-focus-styles {
  border-bottom: none;
  border-radius: 0.25rem;
  box-decoration-break: clone;
  box-shadow: variables.$anchor-focus-box-shadow;
  outline: none;
  text-decoration: none;
}

%anchor-focus-styles-inverted {
  @extend %anchor-focus-styles;
  box-shadow: 0 0 0.5rem 0 variables.color("white");
}

%anchor-focus-styles-focus-visible {
  &:focus-visible:not(.no-focus) {
    @extend %anchor-focus-styles;
  }
}

@mixin no-decoration {
  border-bottom-color: transparent !important;
}
@each $bp in map.keys(variables.$breakpoints) {
  @include breakpoints.bp-up($bp) {
    @include breakpoints.bp-selector("%", anchor, $bp, no-decoration) {
      @include no-decoration;
    }
  }
}

@mixin arrow-after($content) {
  &::after {
    content: $content;
    margin-left: 0.25rem;
  }
}

.pagelink,
.link-arrow-right {
  @include arrow-after("→");
}

.pagelink-down,
.link-arrow-down {
  @include arrow-after("↓");
}

@mixin arrow-before($content) {
  &::before {
    content: $content;
    margin-right: 0.25rem;
  }
}
.pagelink-left,
.link-arrow-left {
  @include arrow-before("←");
}

@mixin anchor-border-bottom($color) {
  border-bottom: 0.0625rem solid $color;
}

%anchor {
  @extend %anchor-state-styles;
  @extend %anchor-focus-styles-focus-visible;

  text-decoration: none;
  @include anchor-border-bottom(transparent);

  &.no-decoration {
    @extend %anchor-no-decoration;
  }

  &.disabled {
    @extend %anchor-no-decoration;
    color: variables.color("gray", 300);
    cursor: default !important;
    pointer-events: none;
  }

  &[class^="pagelink"],
  &[class*=" pagelink"] {
    display: inline;
    margin-bottom: 0;
    margin-top: 1rem;
    @include text.text-sizing(0.875rem, 1.25rem);

    @include mq.mq($from: md) {
      @include text.text-sizing(1rem, 1.5rem);
    }
  }
}

.highlight {
  background-color: variables.color(link, bg);
  display: inline-block;

  &:hover,
  &:active {
    @extend %font-sans-normal-700;
    color: variables.color("white"); // stylelint-disable-line declaration-property-value-disallowed-list
  }

  &:hover {
    background-color: variables.color(link, hover, color);
  }

  &:active {
    background-color: variables.color(link, active, color);
  }

  &[disabled] {
    background-color: variables.color(link, disabled, bg);
    color: variables.color(link, disabled, color);
    // stylelint-disable property-disallowed-list
    font-style: normal !important;
    font-weight: 400 !important;
    // stylelint-enable property-disallowed-list
  }
}

a,
.a-decoration {
  @extend %anchor;

  @include inverted.inverted {
    @extend %anchor-state-styles-white;
  }
}
.icon-link-subtitle-spacing {
  margin-left: calc(variables.$icon-base-size + $icon-link-icon-spacing);
}

.icon-link-subtitle-container {
  @extend .icon-link-subtitle-spacing;

  span {
    color: variables.color("gray", 600);
    @include text.text-sizing(0.875rem, 1.25rem);
  }
}

.icon-link-style {
  @extend %anchor;
  @extend %anchor-no-decoration;

  border-bottom: 0;
  display: flex;

  @include anchor-state-styles(variables.color(link, color), variables.color(link, hover, color), variables.color(link, active, color), "bottom", span);
  @include inverted.inverted {
    @include anchor-state-styles(variables.color("white"), variables.color("white"), variables.color("white"), "bottom", span);
  }

  span {
    @include anchor-border-bottom(transparent);
  }

  .link-icon-container {
    a {
      @extend %anchor-no-decoration;
      @include anchor-border-bottom(transparent);
    }
  }
}
