@use "../variables";

@mixin transition-base {
  transition: variables.$transition-base;
}

%transition-base {
  @include transition-base;
}

%fade,
.fade {
  transition: opacity variables.$transition-base-duration ease;
  will-change: opacity;
}

$initial-rotation: 0deg;
$all-rotations: 90 180 270 360;

@each $rotation in $all-rotations {
  .rotate-ccw-#{$rotation} {
    @extend %transition-base;
    transform: rotate($initial-rotation);
    &.rotate {
      transform: rotate(-#{$rotation}deg);
    }
  }
  .rotate-ccw-#{$rotation}-svg {
    &.rotate {
      svg {
        transform: rotate(-#{$rotation}deg);
      }
    }
    svg {
      @extend %transition-base;
      transform: rotate($initial-rotation);
    }
  }
}
