@use "sass:list";
@use "../functions/icon";
@use "../typography/fonts";
@use "../variables";

$pw-strengths: (0: "Very weak", 1: "Weak", 2: "Strong", 3: "Very strong");
$pw-strength-successes: (2, 3);
$success-color: variables.color(pw-strength, "strength-#{list.nth($pw-strength-successes, list.length($pw-strength-successes))}");

.pw-strength {
  flex: 0 0 100%;

  &[data-strength] {
    .pw-strength-text {
      @extend %font-sans-normal-700;
    }
  }

  @each $i, $text in $pw-strengths {
    $color: variables.color(pw-strength, "strength-#{$i}");

    &[data-strength="#{$i}"] {
      .pw-strength-bar {
        background-color: $color;
        width: 25% * ($i + 1);
      }

      .pw-strength-text {
        &::before {
          color: if(list.index($pw-strength-successes, $i) == null, $color, $success-color);
          content: $text;
        }
      }

      @if list.index($pw-strength-successes, $i) {
        .checkmark {
          display: flex;
        }
      }
    }
  }

  .pw-strength-indicators {
    background-color: variables.color("gray", 300);
    display: flex;
    height: 0.25rem;
    margin-bottom: 0.25rem;
    margin-top: 0.5rem;
  }

  .pw-strength-bar {
    height: 100%;
    width: 0;
  }

  .pw-strength-text {
    @extend %font-sans-italic-400;

    &::before {
      color: variables.color("gray", 600);
      content: "Password must be \"strong\" or better.";
    }
  }

  .checkmark {

    align-items: center;
    background-color: $success-color;
    display: none;
    height: 1rem;
    justify-content: center;
    margin-left: 0.5rem;
    width: 1rem;
    @include icon.icon-circle((xs: 1rem, md: 1rem), (xs: 0.125rem, md: 0.125rem), (xs: 1rem, md: 1rem));

    .icon {
      height: 0.75rem;
      width: 0.75rem;
    }
  }
}

.show-password {
  @extend %font-sans-normal-400;
  &::before {
    content: "Show password";
  }

  &.show {
    &::before {
      content: "Hide password";
    }
  }
}
