@use "sass:map";
@use "../variables";
@use "../utilities/grid";
@use "../mq";

@-ms-viewport {
  width: auto;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;

  header,
  footer {
    flex: 0 0 auto;
  }

  .minimal-container {
    margin-left: auto;
    margin-right: auto;
    max-width: calc(100% - 2.5rem);
    text-align: left;
    width: 50rem !important;
  }

  .page-container {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    .page-content {
      background: variables.color("white");
      &:last-of-type {
        flex: 1 1 auto;
      }
    }
  }

  .content-container {
    flex: 1 0 auto;
    min-height: 1px; // Required to fix buggy IE11 height rendering

    &.minimal-container {
      margin-bottom: 4rem;
    }
  }
}

.is-ios * {
  cursor: pointer;
}

.page-margins {
  @extend %container-padding;
}

.page-header {
  position: fixed;
  top: 0;
  transition: transform variables.$transition-base-duration ease;
  width: 100%;
  z-index: deep-map-get(variables.$z-indexes, header);

  &.alert-removed {
    transform: translateY(0) !important;
    transition: none;

    .alert {
      display: none !important;
    }
  }

  @include mq.mq($from: xl) {
    max-width: map.get(variables.$grid-max-widths, xl);
    width: 100%;
  }
}

.page-summary .md-to-html :last-child {
  margin-bottom: 0;
}
