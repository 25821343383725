@use "../variables";
@use "../mq";
@use "../typography/fonts";
@use "../utilities/borders";
@use "../utilities/shadows";

$notification-height-base: 9rem;
$notification-spacing-md: 2rem;
$notification-spacing-sm: 1.5rem;
$notificaiton-header-line-height: 1.5rem;
$notification-header-padding: 0.5rem;
$notification-header-height: $notificaiton-header-line-height + (2 * $notification-header-padding);
$notification-body-height: $notification-height-base - $notification-header-height;
$notification-btn-close-size: 1.25rem;

.notification {
  @extend %border-radius-1;
  @extend %box-shadow-4;
  display: flex;
  flex-direction: column;
  height: $notification-height-base;
  pointer-events: auto;
  z-index: deep-map-get(variables.$z-indexes, global-alerts);

  @each $t in (danger, info, success) {
    &#{&}-#{$t} {
      background-color: variables.color($t, color);
      border: 0.125rem solid variables.color($t, color);
      .body {
        background-color: variables.color($t, bg);
      }
    }
  }
  &.notification-warning {
    background-color: variables.color(warning, border-color);
    border: 0.125rem solid variables.color(warning, border-color);
    .body {
      background-color: variables.color(warning, bg);
    }
  }
  .body {
    @extend %font-sans-normal-500;
    color: variables.color("gray", 800);
    height: $notification-body-height;
    overflow: hidden;
    padding: 0.75rem 1rem;
    &.notification-danger {
      color: variables.color(danger, color);
    }
  }
  .header {
    border-radius: 0.25rem 0.25rem 0 0;
    display: flex;
    flex-direction: row;
    height: $notification-header-height;
    justify-content: space-between;
    line-height: $notificaiton-header-line-height;
    padding: $notification-header-padding;

    .title {
      display: flex;
      flex-direction: row;
      h5 {
        margin: auto 0 auto 0.25rem;
      }
      .icon {
        height: 1.75rem;
        margin: auto;
        width: 1.75rem;
      }
    }
    .icon-button {
      .icon {
        height: $notification-btn-close-size;
        width: $notification-btn-close-size;
      }
    }
  }
}

.notification-container {
  bottom: $notification-spacing-sm;
  display: flex;
  flex-direction: column;
  height: auto;
  pointer-events: none;
  position: fixed;
  right: $notification-spacing-sm;
  width: calc(100vw - (2 * #{$notification-spacing-sm}));
  z-index: deep-map-get(variables.$z-indexes, global-alerts);

  @include mq.mq($from: sm) {
    bottom: $notification-spacing-md;
    right: $notification-spacing-md;
    width: 25rem;
  }
}
