@use "borders";
@use "../mq";

@mixin vertical-dividers($half-space-between-items) {
  gap: 1rem $half-space-between-items;
  .item {
    &:not(:last-child) {
      border-right: borders.$border-200;
      padding-right: $half-space-between-items;
    }
  }
}

@mixin horizontal-dividers($half-space-between-items) {
  gap: $half-space-between-items 1rem;
  .item {
    &:not(:last-child) {
      border-bottom: borders.$border-200;
      padding-bottom: $half-space-between-items;
    }
  }
}

.interpolate-divider {
  $top-divider-border: var(--border-200);
  $top-divider-padding: 1rem;
  & + & {
    border-top: $top-divider-border;
    margin-top: 1.5rem;
    padding-top: $top-divider-padding;
    &.layout-section {
      margin-top: 1rem;
    }
    &.no-spacing {
      margin-top: 0;
      padding-top: 0;
    }
    &.section-small {
      margin-top: 0.5rem;
      padding-top: 0.5rem;
    }
  }
  &.top-divider {
    border-top: $top-divider-border;
    padding-top: $top-divider-padding;
  }
  &.form-section {
    margin-top: 0;
  }
}

.interpolate-divider-accent {
  & + &,
  & + .portal-loader > &,
  *:is(.portal-loader, div):has(>&) + *:is(.portal-loader, div) > & {
    padding-top: 1rem;
  }
  &:not(:empty, :has(>*:empty)) + &,
  &:not(:empty) + .portal-loader > &,
  *:is(.portal-loader, div):has(>&) + *:is(.portal-loader, div) > & {
    border-top: var(--accent-2-border);
  }
  &:empty,
  &:has(>*:empty) {
    display: none;
  }
  @include mq.mq($from: lg) {
    div:has(>&) + .page-grid-side-bar > & {
      border: none !important;
    }
  }
}
