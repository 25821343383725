@use "sass:map";
@use "../functions/gradient";
@use "../functions/headers";
@use "../variables";
@use "../mq";

$bl-blue: variables.color("blue", 700);
$bl-green: variables.color("lime", 700);

.overlay-black,
.overlay-blue,
.overlay-green-to-blue,
.overlay-green-to-green-dark,
.overlay-blue-to-blue-dark {

  color: variables.color("white"); // stylelint-disable-line declaration-property-value-disallowed-list
  @include headers.h-list(map.keys(variables.$h-props)) {
    color: variables.color("white"); // stylelint-disable-line declaration-property-value-disallowed-list
  }
}

.overlay-black {
  background-color: rgba(variables.color("black"), 0.64);
}

.overlay-blue {
  background-color: rgba(variables.color("blue", 700), 0.88);
}

.overlay-white {
  background-color: rgba(variables.color("white"), 0.88);
}

.overlay-green-to-blue {
  mix-blend-mode: multiply;
  @include gradient.gradient-directional(variables.color("lime", 700), variables.color("blue", 700), to bottom right);
}

.overlay-green-to-green-dark {
  @include gradient.gradient-directional(variables.color("lime", 700), variables.color("lime", 900), to bottom right);
}

.overlay-blue-to-blue-dark {
  @include gradient.gradient-directional(variables.color("blue", 700), variables.color("blue", 900), to bottom right);
}

.overlay-radial-gray-to-transparent {
  &::after {
    background-image: radial-gradient(
        150% 100% at bottom left,
        rgba(variables.color("black"), 0.75),
        rgba(variables.color("black"), 0)
    );
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    @include mq.mq($from: md) {
      background-image: radial-gradient(
          farthest-side at bottom left,
          rgba(variables.color("black"), 0.75),
          rgba(variables.color("black"), 0)
      );
    }
  }
}
