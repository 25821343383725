@use "sass:map";
@use "sass:meta";
@use "functions/types";
@use "functions/map" as functions-map;
@use "functions/case";
@use "functions/string";
@use "functions/color";

////
/// From /web/assets/stylesheets/_variables.scss
////

/// Boolean flag for whether or not we are compiling styles in prod
$prod: (meta.function-exists("is-prod") and is-prod() == true) or false;

// stylelint-disable length-zero-no-unit
/// Breakpoint values
$breakpoints: (
  xs: 0em,
  sm: 36em,
  md: 48em,
  lg: 62em,
  xl: 75em,
);
// stylelint-enable length-zero-no-unit
/// Media query breakpoint values
$mq-breakpoints: $breakpoints;
$mq-show-breakpoints: if($prod, (), map.keys($breakpoints));

/// Grid gutter widths
$grid-gutter-widths: (
  xs: 1.25rem,
  md: 1.5rem,
  lg: 2rem,
  xl: 3rem);
/// Grid max widths
$grid-max-widths: (xl: 92rem);
/// Grid columns
$grid-columns: 24;
/// Grid column gap
$grid-column-gap: 1rem;
$grid-border-width: 0.125rem;

/// Spacer values
$spacers: (
  "0": 0,
  "025": 0.25rem,
  "05": 0.5rem,
  "075": 0.75rem,
  "1": 1rem,
  "15": 1.5rem,
  "175": 1.75rem,
  "2": 2rem,
  "3": 3rem,
  "4": 4rem,
  "5": 5rem);

$base-colors: bl-colors-map();
$primitive-colors: bl-colors-primitive();

/// Transforms base color names into hex values
/// @param {$base-colors} $base-colors
/// @return {String} - Color hex value
@function base-color($args...) {
  @return deep-map-get($base-colors, $args...);
}

/// App theme colors
$colors: (
  "white": deep-map-get($primitive-colors, "white"),
  "black": deep-map-get($primitive-colors, "black"),

  danger: (
    bg: base-color("red", 100),
    border-color: base-color("red", 700),
    color: base-color("red", 700),
    active: (
      color: base-color("red", 900),
    ),
    hover: (
      color: base-color("red", 500),
    ),

  ),
  warning: (
    bg: base-color("yellow", 100),
    border-color: base-color("yellow", 700),
    color: base-color("yellow", 900),
    alt-color: base-color("yellow", 700),
  ),
  info: (
    bg: base-color("teal", 100),
    border-color: base-color("teal", 700),
    color: base-color("teal", 700),
  ),
  success: (
    bg: base-color("green", 100),
    border-color: base-color("green", 700),
    color: base-color("green", 700),
  ),
  link: (
    bg: var(--accent-1-100),
    bg-dec: var(--accent-1-100-dec),
    color: var(--accent-1-700),
    color-dec: var(--accent-1-700-dec),

    active: (
      color: var(--accent-1-900),
      color-dec: var(--accent-1-900-dec),
    ),
    disabled: (
      bg: base-color("gray", 100),
      bg-dec: #{color.to-rgb(base-color("gray", 100))},
      color: base-color("gray", 600),
      color-dec: #{color.to-rgb(base-color("gray", 600))},
    ),
    hover: (
      color: var(--accent-1-500),
      color-dec: var(--accent-1-500-dec),
    ),
  ),
  pw-strength: (
    strength-0: base-color("red", 700),
    strength-1: base-color("yellow", 700),
    strength-2: base-color("lime", 500),
    strength-3: base-color("green", 500)
  ),
  disabled: (
    color: base-color("gray", 600),
    border-color: base-color("gray", 400),
  ),
);

/// Transforms color names into hex values
/// @param {$colors} $colors
/// @return {String} - Color hex value
@function color($args...) {
  @return deep-map-get(functions-map.deep-map-merge($base-colors, $colors), $args...);
}

/// Accent color options
$accent-colors: ("blue", "teal");

$border-radius-0125: 0.125rem;
$border-radius-1: 0.25rem;

$font-family-fallback:  -apple-system, blinkmacsystemfont, "Segoe UI", roboto, "Helvetica Neue", arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-monospace: sfmono-regular, menlo, monaco, consolas, "Liberation Mono", "Courier New", monospace !default;

$font-weight-bold: 700;

$fonts: (
  sans: (
    font-family: ("IBM Plex Sans", $font-family-fallback),
    font-styles: (
      normal: (
        400: (family-postfix: ""),
        500: (family-postfix: "Medium"),
        $font-weight-bold: (family-postfix: "Bold")
      ),
      italic: (
        400: (family-postfix: "Italic"),
        $font-weight-bold: (family-postfix: "Bold Italic")
      )
    )
  )
);

$default-line-height-md: 1.5rem;

/// A map of standard font sizes
$font-sizes: (
  small: (
    xs: text-sizing(0.875rem, 1.25rem) ,
    md: text-sizing(0.875rem, 1.25rem)),
  default: (
    xs: text-sizing(0.875rem, 1.25rem),
    md: text-sizing(1rem, $default-line-height-md)),
  large: (
    xs: text-sizing(1rem, 1.5rem),
    md: text-sizing(1.25rem, 2rem)));

/// A map of header (`<h1>`, `<h2>`, etc.) styles
$h-props: (
  h1: (
    xs: map.merge(
      (
        extend: ".font-sans-normal-700",
        color: color("black"),
        margin: 0,
      ),
      text-sizing(2rem, 2.25rem)
    ),
    md: text-sizing(4rem, 4.5rem)
  ),
  h2: (
    xs: map.merge(
      (
        extend: ".font-sans-normal-700",
        color: color("black"),
        margin: 0 0 1rem 0,
      ),
      text-sizing(1.5rem, 2rem)
    ),
    md: text-sizing(2.25rem, 3rem)
  ),
  h3: (
    xs: map.merge(
      (
        extend: ".font-sans-normal-700",
        color: color("black"),
        margin: 0 0 0.5rem 0,
      ),
      text-sizing(1.25rem, 1.75rem)
    ),
    md: text-sizing(1.5rem, 2rem)
  ),
  h4: (
    xs: map.merge(
      (
        extend: ".font-sans-normal-500",
        color: color("black"),
        letter-spacing: 0.5px,
        margin: 0,
        text-transform: uppercase
      ),
      text-sizing(0.875rem, 1.25rem)
    )
  ),
  h5 : (
    xs: map.merge(
      (
        extend: ".font-sans-normal-500",
        color: color("black"),
        margin: 0 0 0.5rem 0,
      ),
      text-sizing(1.125rem, 1.5rem)
    ),
    md: text-sizing(1.25rem, 1.75rem)
  ),
  h6: (
    xs: map.merge(
      (
        extend: ".font-sans-normal-400",
        color: color("gray", 600),
        margin: 0,
        text-transform: uppercase
      ),
      text-sizing(0.75rem, 1rem)
    )
  )
);

$font-weights-normal: map.keys(deep-map-get($fonts, sans, font-styles, normal));

$bondlink-bar-height-xs: 2rem;
$bondlink-bar-height-md: 3rem;
$bondlink-bar-padding-lr-xs: 0.75rem;
$bondlink-bar-padding-xs: 0.5rem $bondlink-bar-padding-lr-xs;
$bondlink-bar-padding-md: 0.75rem 1rem;

@function btn-padding-diff($font-size, $line-height) {
  @return calc(($line-height - $font-size) / 2);
}

@function btn-padding($default-padding, $font-size, $line-height) {
  $vertical-diff: btn-padding-diff($font-size, $line-height);
  @return ($default-padding - $vertical-diff) $default-padding;
}

$btn-font-size: 1rem;
$btn-line-height: 1rem;
$btn-xs-padding: btn-padding(0.75rem, $btn-font-size, $btn-line-height);
$btn-md-padding: btn-padding(0.875rem, $btn-font-size, $btn-line-height);
$btn-border-width: 0.125rem;
$btn-small-font-size: 0.875rem;
$btn-small-line-height: 0.875rem;
$btn-small-xs-padding: btn-padding(0.5rem, $btn-small-font-size, $btn-small-line-height);
$btn-small-md-padding: btn-padding(0.625rem, $btn-font-size, $btn-line-height);
$btn-small-border-width: 0.0625rem;
$btn-arrow-padding: 0.5rem;
$btn-callout-action-mb: 1rem;

$btn-vertical-diff: btn-padding-diff($btn-font-size, $btn-line-height);
$btn-small-vertical-diff: btn-padding-diff($btn-small-font-size, $btn-small-line-height);

$tooltip-font-size: 0.875rem;
$tooltip-line-height: 1.25rem;
$tooltip-container-padding: 1rem;

$input-active-border-color: color(link, color);
$input-border-color: color("gray", 300);
$input-border-radius: $border-radius-1;
$input-border-width: 0.125rem;
$input-disabled-bg: color("gray", 100);
$input-disabled-color: color("gray", 500);
$input-padding-xs: 0.5rem;
$input-padding-md: 0.625rem;
$input-xs-font-size: 1rem;
$input-md-font-size: $input-xs-font-size;
$input-xs-line-height: 1.25;
$input-md-line-height: $input-xs-line-height;
$input-xs-line-height-with-rem: 1.5rem; // for use with text-sizing()
$input-md-line-height-with-rem: $input-xs-line-height-with-rem; // for use with text-sizing()
$input-height: (($input-border-width + $input-padding-xs) * 2) + $input-xs-line-height-with-rem;
$input-height-md: (($input-border-width + $input-padding-md) * 2) + $input-md-line-height-with-rem;
$input-margin-bottom: 1rem;

$label-margin-bottom: 0.25rem;
$label-height: $input-xs-font-size + ($label-margin-bottom * 2);

$error-text-mt: 0.25rem;

$scale: 1.05;
$transition-base-duration: 0.2s;
$transition-base: all $transition-base-duration ease;

/// Direction map
$direction-map: (
  "": (""),
  x: (left, right),
  y: (bottom, top),
  t: (top),
  r: (right),
  b: (bottom),
  l: (left));

// IF YOU ADD OR MODIFY A NEW Z-INDEX HERE, PLEASE UPDATE THE TYPINGS IN WEB/ASSETS/SCRIPTS/BONDLINK.TS: BondlinkConfigStatic
$z-indexes: (
  below-all: -1,
  base: 1,
  icon: 5,
  context-menu: 100,
  component-loader: 500,
  dropdown: 1000,
  header: 1010,
  nav-menu-background: 1018,
  nav-menu: 1020,
  nav-bondlink-bar: 1022,
  drafts-pill: 1025,
  filters-background: 1027,
  filters-container: 1028,
  filters: 1029,
  modal-background: 1030,
  modal: 1040,
  lightbox: 1050,
  disclaimer-modal-background: 1060,
  disclaimer-modal: 1070,
  tooltip: 2000,
  page-loader: 3000,
  global-alerts: 4000,
  above-all: 9999,
);

$toggle-switch-height: 2rem;
$toggle-switch-transition: 0.25s ease;
$toggle-switch-width: 4.5rem;

:root {
  --accent-2-border: 0.5rem solid #{var(--accent-2-700)};
}
$accent-2-border: var(--accent-2-border);

$nav-menu-width: 15rem;
$issuer-nav-bar-heights: (
  xs: 3.375rem,
  md: 5rem,
  lg: 3rem,
);
$issuer-nav-bar-sticky-heights: (
  xs: 3.375rem,
  md: 4rem,
);
$bank-nav-bar-padding-x: (
  xs: 0.75rem,
  md: 1.5rem,
  lg: 4rem,
);
$bank-nav-bar-padding-y: (
  xs: 0.75rem,
  md: 1rem,
);
$bank-nav-bar-xs-height: 8.625rem;
$bank-nav-bar-md-height: 5rem;
$bank-nav-bar-heights: (
  xs: calc($bank-nav-bar-xs-height + (deep-map-get($bank-nav-bar-padding-y, xs) * 2)),
  md: calc($bank-nav-bar-md-height + (deep-map-get($bank-nav-bar-padding-y, md) * 2)),
);
$bank-nav-bar-sticky-padding-y: (
  xs: 0.5rem,
);
$bank-nav-bar-sticky-xs-height: 2.5rem;
$bank-nav-bar-sticky-md-height: 3rem;
$bank-nav-bar-sticky-heights: (
  xs: calc($bank-nav-bar-sticky-xs-height + (deep-map-get($bank-nav-bar-sticky-padding-y, xs) * 2)),
  md: calc($bank-nav-bar-sticky-md-height + (deep-map-get($bank-nav-bar-sticky-padding-y, xs) * 2)),
);

$nav-bar-actions-column-props: (
  align-items: flex-end,
  display: flex,
  flex-flow: column nowrap,
  justify-content: center,
);
$nav-bar-actions-row-props: (
  align-items: center,
  display: flex,
  flex-flow: row nowrap,
  justify-content: space-between,
);
$issuer-nav-bar-actions-props: (
  md: $nav-bar-actions-column-props,
  lg: $nav-bar-actions-row-props,
  xl: $nav-bar-actions-row-props,
);
$issuer-nav-bar-sticky-actions-props: (
  lg: functions-map.deep-map-merge($nav-bar-actions-row-props, (
    align-items: flex-end,
  )),
);
$issuer-nav-logo-heights: (
  xs: 8rem,
);

$roadshow-header-md-height: 4.5rem;

$issuer-logo-widths: (
  md: 28rem,
  lg: 25rem,
  xl: 28rem,
);

$issuer-logo-offset-lg: 2rem;
$issuer-logo-offset-xl: 4rem;

$issuer-masthead-heights: (
  xs: 10rem,
  md: 25rem,
  lg: 25rem,
  xl: 25rem,
);
$issuer-masthead-home-heights: (
  xs: 12.5rem,
  md: 30rem,
  lg: 30rem,
  xl: 30rem,
);

$bank-masthead-heights: (
  xs: 10rem,
  md: 15rem,
);

$deal-bl-bar-xs: 2.375rem;
$deal-bl-bar-md: 5.125rem;
$deal-bl-bar-lg: 3.125rem;

$deal-firm-bar-xs: 2.5rem;
$deal-firm-bar-md: 5rem;
$deal-firm-bar-lg: 3.25rem;

$deal-nav-bar-heights: (
  xs: $deal-bl-bar-xs + $deal-firm-bar-xs + map.get($issuer-nav-logo-heights, xs),
  md: $deal-bl-bar-xs + $deal-firm-bar-md,
  lg: $deal-bl-bar-xs + $deal-firm-bar-lg,
);

$deal-masthead-heights: (
  xs: 10rem,
  md: 15rem,
  lg: 15rem,
);

$btn-circle-diameter: 3.5rem;

$card-issuer-overview-sm-w: 24rem;
$card-issuer-overview-padding: 0.75rem;

$portal-modal-width-large: 59rem;
$portal-modal-width-small: 35rem;

// Victory chart colors
// If changing these colors be sure to update victoryTheme.ts
$chart-blue-light-summary: color("blue", 500);
$chart-blue-summary: color("blue", 700);

$chart-forest-light: color("teal", 500);
$chart-forest:color("teal", 700);

$chart-green-light:color("lime", 500);
$chart-green: color("lime", 700);

$chart-blue-light: color("indigo", 500);
$chart-blue: color("indigo", 700);

$chart-orange-light: color("orange", 500);
$chart-orange: color("orange", 700);

$chart-gray: color("gray", 600);
$chart-gray-light: color("gray", 400);

$chart-magenta-light: color("magenta", 500);
$chart-magenta: color("magenta", 700);

$anchor-focus-box-shadow: 0 0 0.5rem 0 color("blue", 700);

$icon-default-line-height: line-height(md, default, $font-sizes);
$icon-base-size: calc(1em * var(--line-height, #{$icon-default-line-height}));
