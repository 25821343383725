@use "../functions/overflow";
@use "../functions/overlay";
@use "../functions/text";
@use "../utilities/borders";
@use "../utilities/shadows";
@use "../utilities/spacing";
@use "../variables";
@use "../mq";

.modal-background {
  @include overlay.overlay(0, 0.5, modal-background);
}

.modal {
  @include overlay.overlay(0, 1, modal, unset);

  button.btn-close {
    position: absolute;
    right: 0;
    top: 0;
  }

  .inline-edit-buttons {
    margin-bottom: 2.5rem;
  }

  .disclaimer-edit-buttons {
    margin-bottom: 1rem;
  }
}

.content-modal {
  .modal-content {
    background-color: transparent;
  }

  .modal-footer {
    @extend .bt-0125;
    @extend .pt-15;

    &.bt-0 {
      border-top: none !important;
    }
  }
}

.modal-open,
.filters-open {

  position: static;
  @include overflow.overflow(hidden);

  .modal {
    @include overflow.overflow(auto, y);
    @include overflow.overflow(hidden, x);
  }
}

$modal-dialog-m-sm: 1rem;
.modal-dialog {
  @extend %border-radius-1;
  @extend %box-shadow-4;

  background-color: variables.color("white");
  margin: $modal-dialog-m-sm;
  position: relative;

  @include mq.mq($from: md) {
    .modal-md & {
      margin: 2vh auto;
      width: 45rem;
    }
  }
}

.modal-viewport {
  align-items: flex-end;
  backdrop-filter: blur(0.25rem);
  display: flex;
  justify-content: center;
  min-height: 100%;
  @include mq.mq($from: md) {
    align-items: flex-start;
  }
}

.modal-content {
  background-color: var(--accent-2-100);
  padding: 1.5rem;

  &[data-closeable="false"] {
    max-height: calc(100vh - #{$modal-dialog-m-sm * 2});

    @include mq.mq($from: md) {
      max-height: 96vh;
    }

    .modal-body {

      flex: 1 1 auto;
      @include overflow.overflow(auto, y);

      @at-root div {
        iframe.email-preview {
          border: none;
          margin-top: 1rem;
          min-height: 40vh;
          width: 100%;
        }
      }
    }

    &,
    form {
      display: flex;
      flex-direction: column;
      @include overflow.overflow(hidden, y);
    }

    // In this block of data-closeable="false" we need to add a bit of
    // padding to the form element to allow the scaling of the buttons in the
    // modal so they don't clip. Then we ad negative margin to place the form
    // back where it was before the padding.
    form {
      margin-bottom: -1.75rem;
      margin-left: -1.75rem;
      padding-bottom: 1.75rem;
      padding-left: 1.75rem;
    }
  }

  @include mq.mq($from: md) {
    padding: 3rem;
  }
}

.modal-title {
  margin-bottom: 1rem;
  margin-top: 0;

  h2 {
    @include text.text-sizing(1.5rem, 2rem);

    @include mq.mq($from: md) {
      @include text.text-sizing(2rem, 2.5rem);
    }
  }
}

.modal-body {
  > *:last-child {
    margin-bottom: 0;
  }
}

.modal-footer {
  justify-content: flex-start;
  margin-bottom: -1rem;
  margin-top: 1.5rem;

  .btn {
    margin-right: 1rem;
    margin-top: 1rem;
  }
}

// To handle multiple modals, we'll set the z-index for all modals that are
// *not* the latest to match the original modal-background z-index.
.modal-moved-back {
  z-index: deep-map-get(variables.$z-indexes, modal-background);
}
.modal-body .draft-status-cta {
  background-color: variables.color("white");
  .btn-callout {
    margin: 0;
  }
}

$modal-p-top-lg: 2rem;
$modal-p-top-xs: 1.5rem;
$modal-p-lg: 3rem;
$modal-p-sm: 2rem;
$modal-p-xs: 1.5rem;

%portal-like-modal {
  $p-xs: $modal-p-xs;
  $p-sm: $modal-p-sm;
  $p-lg: $modal-p-lg;

  $modal-sm-size-sm: deep-map-get(variables.$h-props, h3, xs, font-size) * deep-map-get(variables.$h-props, h3, xs, line-height);
  $modal-sm-size-md: deep-map-get(variables.$h-props, h3, md, font-size) * deep-map-get(variables.$h-props, h3, md, line-height);

  $modal-lg-size-sm: deep-map-get(variables.$h-props, h2, xs, font-size) * deep-map-get(variables.$h-props, h2, xs, line-height);
  $modal-lg-size-md: deep-map-get(variables.$h-props, h2, md, font-size) * deep-map-get(variables.$h-props, h2, md, line-height);

  &-root {
    position: fixed;
    z-index: deep-map-get(variables.$z-indexes, modal);
  }

  .modal-content {
    padding: 0;
  }

  .modal-dialog {
    $modal-dialog-m-x: $modal-dialog-m-sm * 2;
    margin: $modal-dialog-m-sm;
    width: calc(100% - $modal-dialog-m-x);

    @include mq.mq($from: sm) {
      @at-root .modal-sm#{&} {
        max-width: variables.$portal-modal-width-small;
      }
    }

    @include mq.mq($from: md) {
      margin: 2vh auto;
      @at-root .modal-lg#{&} {
        .modal-body {
          padding: $modal-p-top-lg $p-lg $p-lg;
        }
      }

      @at-root .modal-sm#{&} {
        .modal-body {
          padding: $modal-p-top-xs $p-sm $p-sm;
        }
      }
    }

    @include mq.mq($from: lg) {
      @at-root .modal-lg#{&} {
        margin: 2vh auto;
        max-width: variables.$portal-modal-width-large;
      }
    }
  }

  .modal-body {
    padding: $p-xs;
    h3 {
      margin-bottom: 1rem;
    }

    p {
      margin: 0;
    }

    .md-to-html p:not(:last-child) {
      margin-bottom: 1rem;
    }

    form {
      .modal-note {
        border-bottom: borders.$border-200;
        color: rgb(51, 51, 51);
        margin-bottom: 1rem;
        padding-bottom: 1rem;
      }
    }
  }

  .modal-header {
    @extend %box-shadow-1;
    background-color: var(--accent-2-700);
    border-radius: 0.25rem 0.25rem 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .modal-title {
      align-items: center;
      display: flex;
      margin: 0;
      padding: 1rem;

      .icon {
        @at-root .modal-sm#{&} {
          height: $modal-sm-size-sm;
          width: $modal-sm-size-sm;

          @include mq.mq($from: md) {
            height: $modal-sm-size-md;
            width: $modal-sm-size-md;
          }
        }

        @at-root .modal-lg#{&} {
          height: $modal-lg-size-sm;
          width: $modal-lg-size-sm;

          @include mq.mq($from: md) {
            height: $modal-lg-size-md;
            width: $modal-lg-size-md;
          }
        }
      }
      .modal-title-text {
        margin: 0 0 0 0.5rem;
      }
    }

    .icon-button {
      height: 3rem;
      margin: auto;
      margin-right: 0.75rem;
      width: 3rem;

      .icon {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }

  .confirmation-error .alert {
    margin-bottom: 0;
    margin-top: 1.5rem;
  }
}

/* stylelint-disable-next-line no-duplicate-selectors */
.modal {
  @extend %portal-like-modal;

  /* stylelint-disable-next-line no-duplicate-selectors */
  button.btn-close {
    margin-right: 1rem;
    position: unset;
  }

  .modal-content {
    background-color: transparent;
  }

  .modal-header {
    align-items: center;
    background-color: var(--accent-1-700);
  }

  .modal-body p {
    margin-bottom: 1rem;
  }

  .modal-body .md-to-html p:last-of-type {
    margin-bottom: 0;
  }

  .modal-footer {
    margin-top: 1rem;
  }
}

.disclaimer-modal {
  .modal-content{
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - #{$modal-dialog-m-sm * 2});

    @include mq.mq($from: md) {
      max-height: 96vh;
    }

    .modal-body {
      display: flex;
      flex-direction: column;
      @include overflow.overflow(hidden, y);

      .disclaimer-content {
        @include overflow.overflow(auto, y);
        .md-to-html:not(:first-of-type){
          margin-top: 1rem;
        }
      }
    }

    .modal-footer {
      button {
        margin-bottom: 0;
      }
    }
  }
}
