@use "../variables";

/// @param {Number} value
/// @output - `box-shadow` prop
@mixin box-shadow($i, $important: false) {
  box-shadow: 0 #{$i * 0.125}rem #{$i * 0.375}rem rgba(variables.color("black"), $i * 0.12) #{if($important, "!important", "")};
}

@function text-shadow-value($i) {
  @return 0 0 #{$i * 0.5}rem rgba(variables.color("black"), (($i - 1) * 0.1) + 0.5);
}

/// @param {Number} value
/// @output - `text-shadow` prop
@mixin text-shadow($i) {
  text-shadow: text-shadow-value($i);
}

/// @param {Number} value
/// @output - `filter` prop
@mixin filter-drop-shadow($i) {
  filter: drop-shadow(text-shadow-value($i));
}
