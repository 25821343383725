@use "../functions/text";
@use "../typography/fonts";
@use "../utilities/shadows";
@use "../variables";

.alert {
  $alert-border-width: 0.125rem;
  $alert-padding: 0.625rem;
  @extend %box-shadow-1;
  @extend %font-sans-normal-700;

  align-items: center;
  border-radius: 0.25rem;
  border-style: solid;
  border-width: $alert-border-width;
  margin-bottom: 1rem;
  opacity: 0;
  padding: $alert-padding;
  transition: opacity variables.$transition-base-duration ease;
  z-index: deep-map-get(variables.$z-indexes, base);

  &:last-child {
    margin-bottom: 0;
  }

  &.pill {
    border-radius: 10rem;
  }

  &.show {
    display: flex;
    opacity: 1;
    position: relative;
  }

  .icon-button {
    color: inherit;
    .icon {
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  .alert-icon {
    flex: 0 0 auto;
    margin: -0.5rem 0;
    padding-right: $alert-padding;

    .icon {
      height: 2rem;
      width: 2rem;
    }
  }

  .alert-body {
    flex: 1 1 auto;
    max-width: 100%;

    :last-child {
      flex: 1 1 auto;
      margin-bottom: 0;
    }
  }

  &-subtle {
    $subtle-icon-height: 1.75rem;
    align-items: flex-start;
    border: none;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    text-align: left;

    .title,
    .body {
      @extend %font-sans-normal-500;
    }
    .title {
      margin-bottom: 1rem;
      @include text.text-sizing(1.25rem, $subtle-icon-height);
      .icon {
        height: $subtle-icon-height;
        margin-right: 0.25rem;
      }
    }
    .body {
      @include text.text-sizing(1rem, 1.5rem);
    }
  }

  &-pushdown {
    background-color: variables.color(danger, color);
    border: none !important;
    border-radius: 0;
    box-shadow: inset 0 -0.75rem 0.75rem -0.75rem rgba(0,0,0,0.24);
    margin-bottom: 0;
    text-align: center;
    z-index: deep-map-get(variables.$z-indexes, nav-menu);
  }

  @each $t in (danger, info, success) {
    &#{&}-#{$t} {
      background-color: variables.color($t, bg);
      border: $alert-border-width solid variables.color($t, border-color);
      color: variables.color($t, color);
    }
  }

  &-warning {
    background-color: variables.color(warning, bg);
    border: $alert-border-width solid variables.color(warning, border-color);
    color: variables.color(warning, color);
  }

  &-no-border {
    border: none;
    box-shadow: none;
  }
}
