.password-input-with-strength {
  .form-input {
    margin-bottom: 0;
  }
}

.bl-tooltip-content-container.password-input-tooltip {
  .bl-tooltip-list-content {
    list-style: inside;

    li {
      display: list-item;
      margin-left: 0 !important;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
