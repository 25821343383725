@use "../variables";
@use "breakpoints";

@mixin icon-circle($sizes, $paddings, $border-radii) {
  overflow: hidden;
  vertical-align: middle;

  @each $bp in (xs, md) {
    @include breakpoints.bp-up($bp) {
      $size: deep-map-get($sizes, $bp);
      $padding: deep-map-get($paddings, $bp);
      $i-size: $size - ($padding * 2);

      border-radius: deep-map-get($border-radii, $bp);
      height: $size;
      padding: $padding;
      width: $size;

      .icon {
        height: $i-size;
        width: $i-size;
      }
    }
  }

  &.btn {
    border: 0;
  }

  .icon {
    color: variables.color("white"); // stylelint-disable-line declaration-property-value-disallowed-list
    margin: 0;
  }
}

%icon-circle {
  background-clip: padding-box; // Fix bleeding of border around circle in IE11
  @include icon-circle((xs: 2.5rem, md: 2.75rem), (xs: 0.5rem, md: 0.625rem), (xs: 1.25rem, md: 1.375rem));

  &-title {
    background-clip: padding-box; // Fix bleeding of border around circle in IE11
    @include icon-circle((xs: 2.75rem, md: 3rem), (xs: 0.5rem, md: 0.625rem), (xs: 1.375rem, md: 1.5rem));
  }
}
