@use "sass:map";
@use "sass:math";
@use "../functions/breakpoints";
@use "../functions/shadows";
@use "../functions/spacing";
@use "../utilities/float";
@use "../variables";
@use "../mq";

@mixin container-spacing($k, $v) {
  #{$k}-left: #{$v};
  #{$k}-right: #{$v};
}

.container,
.container-fluid {
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  @each $bp, $width in variables.$grid-gutter-widths {
    @include breakpoints.bp-up($bp) {
      @include container-spacing(padding, $width);
    }
  }
}

%container-padding {
  @each $bp, $width in variables.$grid-gutter-widths {
    @include breakpoints.bp-up($bp) {
      @include container-spacing(padding, $width);
    }
  }
}

%container-spacing-negate {
  @each $bp, $width in variables.$grid-gutter-widths {
    @include breakpoints.bp-up($bp) {
      @include container-spacing(margin, -$width);
    }
  }
}

.container {
  @each $bp, $width in variables.$grid-gutter-widths {
    $max-w: breakpoints.bp-leq(variables.$grid-max-widths, $bp, 0);

    @if($max-w != 0) {
      @include mq.mq($from: $bp) {
        max-width: $width + $max-w;
      }
    }
  }
}

@include mq.mq($from: xl) { // stylelint-disable-line order/order
  body {
    background-color: var(--accent-2-100);
  }

  .page-container,
  .page-container-width {
    margin: 0 auto;
    max-width: map.get(variables.$grid-max-widths, xl);
    width: 100%;
  }

  .page-container {
    background-color: variables.color("white");

    @include shadows.box-shadow(2);
  }
}

@mixin style-grid-columns {
  > * {
    @content;
  }
}

@mixin grid-spacing($spacings, $cols-have-y-padding, $cols-have-y-margin) {
  @each $bp, $val in $spacings {
    @include breakpoints.bp-up($bp) {
      @include spacing.margin-x($val * -1);
      @if $cols-have-y-margin {
        @include spacing.margin-y($val * -1);
      }
    }

    @include style-grid-columns {
      @include breakpoints.bp-up($bp) {
        @include spacing.padding-x($val);

        @if $cols-have-y-padding {
          @include spacing.padding-y($val);
        }
      }
    }
  }
}

@mixin grid-spacing-xy($spacings-x, $spacings-y, $cols-have-y-padding, $cols-have-y-margin) {
  @each $bp, $val in $spacings-x {
    @include breakpoints.bp-up($bp) {
      @include spacing.margin-x($val * -1);
    }

    @include style-grid-columns {
      @include breakpoints.bp-up($bp) {
        @include spacing.padding-x($val);
      }
    }
  }

  @each $bp, $val in $spacings-y {
    @include breakpoints.bp-up($bp) {
      @if $cols-have-y-margin {
        @include spacing.margin-y($val * -1);
      }
    }

    @include style-grid-columns {
      @include breakpoints.bp-up($bp) {
        @if $cols-have-y-padding {
          @include spacing.padding-y($val);
        }
      }
    }
  }
}

.grid {
  display: flex;
  flex-wrap: wrap;
  &.input-row {
    > div[class^="c-"] {
      @include mq.mq($from: md) {
        padding-right: 0.5rem !important;
        &:first-of-type {
          padding-right: 1.5rem !important;
        }
        &.multi-col-spacing {
          padding-right: 1.5rem !important;

          &:last-of-type {
            padding-right: 0.5rem !important;

          }
        }
      }
    }
  }
  &.grid-sx-1 {
    @include grid-spacing((xs: 1rem), false, false);
  }

  &.grid-sx-15 {
    @include grid-spacing((xs: 1.5rem), false, false);
  }

  &.grid-sx-2 {
    @include grid-spacing((xs: 2rem), false, false);
  }

  &.grid-sx-4 {
    @include grid-spacing((xs: 4rem), false, false);
  }

  &.grid-sa-1 {
    @include grid-spacing((xs: 1rem), true, true);
  }

  &.grid-sa-15 {
    @include grid-spacing((xs: 1.5rem), true, true);
  }


  &.grid-sa-2 {
    @include grid-spacing((xs: 2rem), true, true);
  }

  &.grid-sx-md-3 {
    @include grid-spacing((md: 3rem), false, false);
  }

  &.grid-sxy-sm-4-2 {
    @include grid-spacing-xy((sm: 4rem), (xs: 2rem), false, false);
  }

  &.grid-sxy-1-075 {
    @include grid-spacing-xy((xs: 1rem), (xs: 0.75rem), true, true);
  }

  > * {
    @extend %grid-column;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

%grid-column {
  min-height: 1px;
  position: relative;
}

@mixin grid-col-width($val) {
  flex: 0 0 #{$val};
  max-width: #{$val};
}

@mixin grid-col($i, $bp: "") {
  @include breakpoints.bp-class(c, $bp, $i) {
    @extend %grid-column;

    @include breakpoints.bp-up($bp) {
      @include grid-col-width(math.percentage(math.div($i, variables.$grid-columns)));
    }
  }
}

@each $bp in map.keys(variables.$breakpoints) {
  @for $i from 1 through variables.$grid-columns {
    @include grid-col($i, $bp);
  }
}

@mixin two-col-grid($pct1-unitless, $pct2-unitless, $base-padding, $extra-paddings: ()) {
  $pct1: $pct1-unitless * 1%;
  $pct2: $pct2-unitless * 1%;

  .grid-#{$pct1-unitless}-#{$pct2-unitless} {
    @include mq.mq($from: md) {
      display: flex;

      > .c-main {
        margin-right: #{$base-padding};
        @include grid-col-width($pct1);
      }

      > .c-sidebar {
        @include grid-col-width(calc(#{$pct2} - #{$base-padding}));
      }

      > .c-main,
      > .c-sidebar {
        @include spacing.margin-y-0-first-last-child(first, top);
        @include spacing.margin-y-0-first-last-child(last, bottom);
      }
    }

    @each $bp, $padding in $extra-paddings {
      @include mq.mq($from: $bp) {
        > .c-main {
          margin-right: #{$padding};
        }

        > .c-sidebar {
          @include grid-col-width(calc(#{$pct2} - #{$padding}));
        }
      }
    }

    @content;
  }
}

@include two-col-grid(85, 15, 1.5rem, (lg: 1rem)); // stylelint-disable-line order/order

@include two-col-grid(75, 25, 1.5rem, (lg: 2.5rem)) {
  @include mq.mq($from: md) {
    > .c-sidebar {
      border-left: variables.$grid-border-width solid variables.color("gray", 200);
      padding-left: 1.5rem;
    }
  }
}

@include two-col-grid(60, 40, 1.5rem, (lg: 2.5rem)) {
  @include mq.mq($from: md) {
    > .c-sidebar {
      padding-left: 1.5rem;
    }
  }
}

@include two-col-grid(65, 35, 1.5rem, (lg: 2.5rem)) {
  @include mq.mq($from: md) {
    > .c-sidebar {
      padding-left: 1.5rem;
      &.divider {
        border-left: variables.$grid-border-width solid variables.color("gray", 200);
      }
    }
  }
}

@include two-col-grid(50, 50, 1rem, (lg: 2.5rem));

@include two-col-grid(35, 65, 1.5rem, (lg: 2.5rem)) {
  @include mq.mq($from: md) {
    > .c-sidebar {
      padding-right: 1.5rem;
    }
  }
}


$grid-left-margins: (md: 1.5rem, lg: 2.5rem);
$grid-right-margins: (md: 1rem, lg: 1.5rem);
.grid-75-25-simple {
  margin-top: 1.5rem;

  @include mq.mq($from: md) {
    @extend .clear-fix-md;
    margin-top: 2rem;

    &.single-row {
      display: flex;

      .grid-left {
        margin-bottom: 1rem !important;

        p.large {
          margin-bottom: 1rem;
        }
      }

      .grid-right .sidebar-link {
        margin-bottom: 1rem !important;
      }
    }

    .grid-left {
      border-right: variables.$grid-border-width solid variables.color("gray", 200);
      clear: left;
      float: left;
      padding-right: deep-map-get($grid-left-margins, md);
      width: 75%;

      @include mq.mq($from: lg) {
        padding-right: deep-map-get($grid-left-margins, lg);
        width: 75%;
      }
    }

    .grid-right {
      clear: right;
      float: right;
      padding-left: deep-map-get($grid-right-margins, md);
      width: 25%;

      &.border {
        border-left: variables.$grid-border-width solid variables.color("gray", 200);
        margin-bottom: 1rem !important;
      }

      @include mq.mq($from: lg) {
        padding-left: deep-map-get($grid-right-margins, lg);
      }
    }

    .grid-left,
    .grid-right {
      margin-bottom: 0 !important;
      margin-top: 0 !important;
    }
  }

  .grid-right:last-child {
    margin-top: 2rem;
  }
}
