@use "sass:list";
@use "sass:map";
@use "../functions/breakpoints";
@use "../variables";

$dims: ();

// stylelint-disable-next-line scss/no-duplicate-dollar-variables
@for $i from 0 through 20 { $dims: list.append($dims, $i * 5); }

@mixin sizing($dim, $prop) {
  @each $bp in map.keys(variables.$breakpoints) {
    @each $i in $dims {
      @include breakpoints.bp-up($bp) {
        @include breakpoints.bp-class($dim, $bp, $i) {
          #{$prop}: $i * 1% !important;
        }
      }
    }
  }
}

@include sizing(h, height);
@include sizing(w, width);

.max-w-max-content {
  max-width: max-content !important;
}

.w-fit-content {
  width: fit-content !important;
}

.w-auto {
  width: auto !important;
}
