@mixin truncate-lines($num-lines) {
  -webkit-line-clamp: $num-lines;
}

.truncate {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

%truncate-lines-disable {
  display: block;
  -webkit-line-clamp: none;
  max-height: unset;
}
