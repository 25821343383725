.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.relative {
  position: relative;
}

$position-z-indexes: (1, 2, 3);

@each $z-index in $position-z-indexes {
  .z-#{$z-index} {
    z-index: $z-index;
  }
}
