@use "../functions/shadows";
@use "../mq";
@use "../variables";

.minimal-layout {
  $back-button-offset: 2rem;
  $max-container-width: 32rem;

  background-color: variables.color("gray", 100);

  &.modal-open,
  &.filters-open {
    position: unset;
  }

  .forgot-password-modal {
    .modal-viewport {
      align-items: flex-start;
      backdrop-filter: none;
      padding: 1.25rem;
      @include mq.mq($from: md) {
        padding: 2rem;
      }
    }
    .modal-dialog {
      margin: 0 auto;
      max-width: $max-container-width;
      .modal-content {
        background: variables.color("white");
        border-radius: 0.25rem;
      }
      .modal-footer {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: space-between;
        .btn {
          width: max-content;
        }
        .close-modal {
          justify-self: end;
          margin-right: 0;
        }
        .alert {
          grid-column-end: 3;
          grid-column-start: 1;
        }
      }
    }
  }

  .minimal-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 0.75rem 0;
    @include mq.mq($from: md) {
      padding: 2rem;
    }
  }

  .back-button {
    align-self: flex-start;
    margin-bottom: 0.75rem;
    @include mq.mq($from: md) {
      left: $back-button-offset;
      position: absolute;
      top: $back-button-offset;
    }
  }

  .custom-indicator {
    margin-bottom: 0.5rem;
    .option-label {
      font-size: 0.875rem;
      @include mq.mq($from: md) {
        font-size: 1rem;
      }
    }
  }

  .page-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: $max-container-width;
    padding: 2rem 1rem;
    .footer-item {
      margin: 0 1rem 0.5rem 1rem;
      white-space: nowrap;
    }
    .bl-copyright {
      color: variables.color("gray", 600);
    }
  }

  .minimal-form-container {
    align-items: center;
    background: variables.color("white");
    border-radius: 0.25rem;
    flex-direction: column;
    max-width: $max-container-width;
    padding: 1.5rem;
    @include shadows.box-shadow(1);

    &.full-width {
      max-width: none;
      width: 100%;
    }

    @include mq.mq($from: md) {
      padding: 2rem;
    }

    .bl-logo-container {
      margin-bottom: 1.5rem;
      .bl-logo {
        height: 2.5rem;
      }
    }

    .minimal-layout-header {
      align-self: flex-start;
      font-size: 1.5rem;
      margin-top: 0;
    }

    .form-footer-container {
      align-items: center;
      display: flex;
      flex-direction: column;
    }
  }
}
