@use "sass:map";
@use "../variables";
@use "breakpoints";

@mixin spacing($prefix, $prop-prefix) {
  @each $bp in map.keys(variables.$breakpoints) {
    @each $class-val, $val in variables.$spacers {
      @each $dir, $props in variables.$direction-map {
        @include breakpoints.bp-up($bp) {
          @include breakpoints.bp-class("#{$prefix}#{$dir}", $bp, $class-val) {
            @each $prop-suffix in $props {
              #{$prop-prefix}#{if($prop-suffix == "", "", "-")}#{$prop-suffix}: #{$val} !important;
            }
          }
        }
      }
    }
  }
}

@mixin spacing-x($prop, $val) {
  #{$prop}-left: #{$val * 0.5} !important;
  #{$prop}-right: #{$val * 0.5} !important;
}

@mixin spacing-y($prop, $val) {
  /* stylelint-disable-next-line scss/selector-no-redundant-nesting-selector */
  & {
    #{$prop}-bottom: #{$val * 0.5} !important;
    #{$prop}-top: #{$val * 0.5} !important;
  }
}

@mixin margin-x($val) { @include spacing-x(margin, $val); }
@mixin margin-y($val) { @include spacing-y(margin, $val); }
@mixin padding-x($val) { @include spacing-x(padding, $val); }
@mixin padding-y($val) { @include spacing-y(padding, $val); }

@mixin margin-y-0-first-last-child($firstOrLast, $topOrBottom, $level: 1) {
  @if($level <= 5) {
    > :#{$firstOrLast}-child:not(.btn *, .inline-edit-buttons, .icon) {
      @include margin-y-0-first-last-child($firstOrLast, $topOrBottom, $level + 1);

      margin-#{$topOrBottom}: 0;
    }
  }
}
