@use "sass:map";
@use "../functions/breakpoints";
@use "../variables";

@each $bp in map.keys(variables.$breakpoints) {
  @each $d in (right, left, center) {
    @include breakpoints.bp-up($bp) {
      @include breakpoints.bp-class(text, $bp, $d) {
        text-align: $d !important;
      }
    }
  }
}

.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify{
  text-align: justify;
}
.text-start {
  text-align: start;
}
.text-end {
  text-align: end;
}
