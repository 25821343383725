@use "sass:list";
@use "sass:map";
@use "sass:string";
@use "../functions/breakpoints";
@use "../functions/string" as functions-string;
@use "../functions/text";
@use "../variables";

// stylelint-disable property-disallowed-list, font-weight-notation

@mixin _font-attrs($family, $style, $weight) {
  $font: bl-font(variables.$fonts, $family, $style, $weight);
  font-style: deep-map-get($font, font-style);
  font-weight: deep-map-get($font, font-weight);
}

// stylelint-disable scss/no-duplicate-dollar-variables
@each $family, $font in variables.$fonts {

  @each $bp in map.keys(variables.$breakpoints) {
    @include breakpoints.bp-up($bp) {
      @include breakpoints.bp-selector("%", font, $bp, $family) {
        font-family: deep-map-get($font, font-family);
      }
    }
  }

  @each $style, $weights in deep-map-get($font, font-styles) {
    @each $weight, $w-meta in $weights {
      $family-postfix: deep-map-get($w-meta, family-postfix);
      $font-name: list.nth(deep-map-get(variables.$fonts, $family, font-family), 1);
      $font-name-no-space: functions-string.str-replace($font-name, " ", "");
      $font-name-hyphenated: functions-string.str-replace($font-name, " ", "-");

      $s: "";
      @if $style == italic {
        $s: i;
      }

      $pf: "";
      $pf-no-space: "";
      @if $family-postfix != "" {
        $pf: " #{$family-postfix}";
        $pf-no-space: "-#{functions-string.str-replace($family-postfix, " ", "")}";
      }

      /**
       * These latin-pi fonts were generated using fontforge and v1 of the the IBM Plex Sans monorepo
       * (versions changed from v6.x to v1 on 2024-08-30)
       * The latin-pi is a merge of the latin-1 and Pi character sets - JDL - 2024-10-16
       */
      @font-face {
        font-display: fallback;
        font-family: $font-name;
        font-style: $style;
        font-weight: $weight;
        src:
          local("#{$font-name}#{$pf}"),
          local("#{$font-name-no-space}#{$pf-no-space}"),
          url("/assets/fonts/#{string.to-lower-case($font-name-hyphenated)}-v1-latin-pi-#{$weight}#{$s}.woff2") format("woff2");
      }

      @each $bp in map.keys(variables.$breakpoints) {
        @include breakpoints.bp-up($bp) {
          // @include bp-selector(if($bp == "xs", ".", "%"), font, $bp, "#{$family}-#{$style}-#{$weight}") {
          @include breakpoints.bp-selector("%", font, $bp, "#{$family}-#{$style}-#{$weight}") {
            @extend #{breakpoints.mk-bp-selector("%", font, $bp, $family)};
            @include _font-attrs($family, $style, $weight);
          }
        }
      }

      @each $bp in map.keys(variables.$breakpoints) {
        @include breakpoints.bp-up($bp) {
          @include breakpoints.bp-selector(".", font, $bp, "#{$family}-#{$style}-#{$weight}") {
            @extend #{breakpoints.mk-bp-selector("%", font, $bp, "#{$family}-#{$style}-#{$weight}")};
          }
        }
      }
    }
  }
}
// stylelint-enable scss/no-duplicate-dollar-variables

@each $size in (default, small, large) {
  %font-size-#{$size},
  .font-size-#{$size} {
    @each $bp in (xs, md) {
      @include breakpoints.bp-up($bp) {
        @include text.text-sizing(font-size($bp, $size, variables.$font-sizes), line-height($bp, $size, variables.$font-sizes));
      }
    }
  }
}

:root {
  --line-height: #{deep-map-get(variables.$font-sizes, default, md, line-height)};
}

html {
  font-size: 100%;
}

body {
  @extend %font-sans-normal-400;
  @extend %font-size-default;

  color: variables.color("gray", 800);

  .inverted {
    color: variables.color("white"); // stylelint-disable-line declaration-property-value-disallowed-list
  }
}

@each $s in (small, large) {
  .#{$s} {
    @extend %font-size-#{$s};
  }
}

strong {
  font-weight: variables.$font-weight-bold;
}
