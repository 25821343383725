@use "../../components/buttons";
@use "../../functions/shadows" as shadowFns;
@use "../../functions/text";
@use "../../typography/fonts";
@use "../../typography/h";
@use "../../utilities/borders";
@use "../../utilities/scrollbar";
@use "../../utilities/shadows";
@use "../../variables";
@use "../../mq";

%multi-value-has-danger {
  .bl-react-select__multi-value__label {
    background-color: variables.color("red", 100);
    color: variables.color(danger, color);
  }
  .bl-react-select__multi-value__remove {
    background-color: variables.color("red", 100);
    .icon {
      fill: variables.color("danger", color);
    }
  }
}

.bl-react-select-wrapper {

  position: relative;
  @include text.text-sizing(variables.$input-xs-font-size, variables.$input-xs-line-height-with-rem);

  // For error state
  &.has-danger {
    .bl-react-select-container {
      .bl-react-select {
        &__control {
          border-color: variables.color(danger, "color");
          color: variables.color(danger, "color");
        }

        &__placeholder {
          color: variables.color(danger, "color");
        }

        &__single-value {
          color: variables.color(danger, "color");
        }

        &__indicator svg {
          fill: variables.color(danger, "color");
        }
      }
    }

    .error-text {
      border-color: variables.color(danger, "color");
      color: variables.color(danger, "color");
      display: block;
    }
  }

  &.form-input {
    .bl-react-select {
      &__control {
        @extend %box-shadow-1;
        border: variables.$input-border-width solid variables.$input-border-color;
        border-radius: variables.$input-border-radius;
        color: variables.color("black");
        max-height: 7rem;
        min-height: variables.$input-height;
        overflow: auto; // stylelint-disable-line declaration-property-value-disallowed-list
        @include mq.mq($from: md) {
          min-height: variables.$input-height-md;
        }
        &--is-focused {
          @extend %focus-shadow;
        }
        &__indicator {
          color: var(--accent-1-700);
        }
        &--is-disabled {
          background-color: variables.$input-disabled-bg;
        }
      }
    }
  }

  &.minimal {
    .bl-react-select {
      &__control,
      &__control--is-focused {
        background-color: transparent;
        border: none;
        border-radius: 0;
        box-shadow: none;
        .bl-react-select__indicator,
        .bl-react-select__single-value {
          color: var(--accent-1-700);
        }
      }
      &__control--is-disabled {
        .bl-react-select__indicator,
        .bl-react-select__single-value {
          color: variables.$input-disabled-color;
        }
      }
      &__indicator,
      &__indicators {
        overflow: hidden;
        padding: 0;
      }
      &__indicator-separator {
        display: none;
      }
      &--is-focused {
        @extend %focus-shadow;
      }
      &__single-value {
        @extend %font-sans-normal-700;
      }
      &__value-container {
        padding: 0;
      }
    }
  }

  .bl-react-select--is-disabled {
    .bl-react-select__indicator {
      .icon {
        color: variables.$input-disabled-color;
      }
    }
  }

  .bl-react-select-container {
    &.remove-disabled {
      pointer-events: none;
      .bl-react-select {
        &__multi-value__label {
          padding-right: 0.375rem;
        }
        &__multi-value__remove {
          display: none;
        }
      }
    }
    .bl-react-select {
      &__control {
        cursor: pointer;
        position: static;
      }
      &__multi-value {
        background-color: var(--accent-1-100);
        &.has-danger {
          @extend %multi-value-has-danger;
        }
        &__label {
          @extend %font-sans-normal-400;
          color: variables.color("black");
        }
      }
      &__multi-value__remove {
        &:hover {
          background-color: transparent;
          cursor: pointer;
        }
        svg {
          color: variables.color("gray", 400);
          height: 0.875rem;
          width: 0.875rem;
        }
      }

      &__menu {
        margin-top: 0.25rem;
        z-index: deep-map-get(variables.$z-indexes, dropdown);
      }

      &__menu-list {
        @extend .select-scrollbar;
        overflow-x: hidden;
      }

      &__option {
        padding: 0.5rem 0.75rem;

        &:active:not(&--is-selected) {
          background-color: var(--accent-1-100);
          color: var(--accent-1-900);
        }

        &:hover:not(&--is-selected) {
          background-color: var(--accent-1-100);
          color: var(--accent-1-500);
        }

        &--is-focused  {
          background-color: transparent;
          color: var(--accent-1-700);
        }

        &--is-selected {
          @extend %font-sans-normal-700;

          background-color: var(--accent-1-700);
          color: variables.color("white"); // stylelint-disable-line declaration-property-value-disallowed-list
        }

        &--is-selected:active {
          background-color: var(--accent-1-900);
        }

        &--is-selected:hover {
          background-color: var(--accent-1-500);
        }
      }

      &__indicator {
        color: var(--accent-1-700);

        .icon.disabled {
          color: variables.$input-disabled-color;
        }
      }

      &__input {
        box-shadow: none;
      }

      &__group-heading {
        @extend %h4;

        background: variables.color("gray", 200);
        color: variables.color("black");
        padding: 0.25rem 0.625rem;
      }

      &__value-container {
        position: static;
      }
    }
  }

  .error-text {
    display: none;
    margin-top: 0.25rem;
    @include text.text-sizing(0.875rem, 1.25rem);
  }
}

.bl-react-select-wrapper.button-select {
  margin-top: 1rem;
  word-break: keep-all;

  .bl-react-select__control {
    border: borders.$border-300;
    display: flex;
    justify-content: center;
    padding: variables.$btn-xs-padding;
    @include shadowFns.box-shadow(1);

    &:active {
      border-color: var(--accent-1-900);
    }

    &:hover {
      border-color: var(--accent-1-500);
      transform: scale(variables.$scale);
      @include shadowFns.box-shadow(3);
    }

    &.bl-react-select__control--is-focused {
      @extend %focus-shadow;
    }

    @include mq.mq($from: md) {
      padding: variables.$btn-md-padding;
    }

    &--is-disabled {
      background-color: deep-map-get(buttons.$btn-secondary, disabled, background);
      border-color: deep-map-get(buttons.$btn-secondary, disabled, border-color);
    }
  }

  .value-container {
    @extend %font-sans-normal-700;
    align-items: center;
    color: var(--accent-1-700);
    display: flex;
    height: 1rem;
    line-height: 1rem;
    text-transform: uppercase;

    &:active {
      color: var(--accent-1-900);
    }

    &:hover {
      color: var(--accent-1-500);
    }

    &.disabled {
      color: deep-map-get(buttons.$btn-secondary, disabled, color);
    }
  }
}

.select-expanded {
  .bl-react-select__control {
    align-items: flex-start;
    height: auto;
    max-height: 20rem !important;
    min-height: 11rem !important;
  }
}

.select-no-indicator {
  .bl-react-select__indicator {
    display: none;
  }
}

.select-no-menu {
  .bl-react-select__menu {
    display: none;
  }
}

.table-select {

  &.bl-react-select-wrapper.minimal{
    background-color: variables.color("gray", 100);
    border: 0.25rem solid variables.color("gray", 100);
    border-radius: 0.25rem;
    .bl-react-select {
      &__control {
        min-height: 1.5rem;
      }
    }
  }
  @include mq.mq($until: md) {
    width: auto !important;
  }
}
