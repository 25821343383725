@use "../functions/icon";
@use "../functions/inverted";
@use "../functions/map";
@use "../functions/text";
@use "../functions/transitions";
@use "../functions/truncate";
@use "../typography/a";
@use "../typography/fonts";
@use "../utilities/borders";
@use "../utilities/shadows";
@use "../variables";
@use "../mq";

@mixin icon-colors($icon-color-map) {
  .icon {
    @each $prop, $val in $icon-color-map {
      .#{$prop} {
        color: $val;
        fill: $val;
      }
    }
  }
}

@mixin btn-rules($btn-rules) {
  @each $prop, $val in $btn-rules {
    #{$prop}: $val;
  }
  @include icon-colors($btn-rules);
}

@mixin btn-states($btn-rules) {
  &:hover {
    @include btn-rules(deep-map-get($btn-rules, hover));
  }

  &:focus {
    @extend %focus-shadow;
  }

  &:active {
    @include btn-rules(deep-map-get($btn-rules, active));
  }

  &[disabled],
  &[data-disabled] {
    @include btn-rules(deep-map-get($btn-rules, disabled));
  }
}

@mixin btn-gen($btn-rules) {
  @include btn-rules(deep-map-get($btn-rules, default));
  @include btn-states($btn-rules);
}

$btn-primary: (
  default: (
    color: variables.color("white"),
    background: variables.color(link, color),
    border-color: variables.color(link, color)
  ),
  hover: (
    color: variables.color("white"),
    background: variables.color(link, hover, color),
    border-color: variables.color(link, hover, color)
  ),
  active: (
    color: variables.color("white"),
    background: variables.color(link, active, color),
    border-color: variables.color(link, active, color)
  ),
  disabled: map.deep-map-merge(variables.color(disabled), (
    background: deep-map-get(variables.color(disabled), border-color)
  )),
);

$btn-primary-inverted: (
  default: (
    color: variables.color("black"),
    background: variables.color("gray", 100),
    border-color: variables.color("gray", 100)
  ),
  hover: (
    color: (variables.color("blue", 500)),
    background: variables.color("white"),
    border-color: variables.color("white"),
  ),
  active: (
    color: (variables.color("blue", 900)),
    background: variables.color("gray", 100),
    border-color: variables.color("gray", 100)
  ),
  disabled: deep-map-get($btn-primary, disabled)
);

$btn-secondary: (
  default: (
    color: variables.color(link, color),
    background: variables.color("white"),
    border-color: variables.color("gray", 300),
  ),
  hover: (
    color: variables.color(link, hover, color),
    border-color: variables.color(link, hover, color)
  ),
  active: (
    color: variables.color(link, active, color),
    border-color: variables.color(link, active, color)
  ),
  disabled: map.deep-map-merge(variables.color(disabled), (
    background: variables.color("gray", 200),
  )),
);

$btn-secondary-inverted: (
  default: (
    color: variables.color("white"),
    background: rgba(0, 0, 0, 0.5),
    border-color: variables.color("gray", 200),
  ),
  hover: (
    color: (variables.color("blue", 500)),
    background: variables.color("white"),
    border-color: variables.color("white")
  ),
  active: (
    color: (variables.color("blue", 900)),
    background: variables.color("gray", 100),
    border-color: variables.color("gray", 100),
  ),
  disabled: (
    color: variables.color("gray", 500),
    background: variables.color("gray", 700),
    border-color: variables.color("gray", 500),
  )
);

$btn-danger-secondary: (
  default: (
    color: variables.color(danger, color),
    background: variables.color("white"),
    border-color: variables.color("gray", 300),
  ),
  hover: (
    color: variables.color(danger, hover, color),
    border-color: variables.color(danger, hover, color)
  ),
  active: (
    color: variables.color(danger, active, color),
    border-color: variables.color(danger, hover, color)
  ),
  disabled: map.deep-map-merge(variables.color(disabled), (
    background: deep-map-get(variables.color(disabled), border-color)
  )),
);

$btn-gray: (
  default: (
    color: variables.color("white"),
    background: variables.color("gray", 800),
    border-color: variables.color("gray", 800),
  ),
  hover: (
    color: variables.color("white"),
    background: variables.color("black"),
    border-color: variables.color("gray", 600),
  ),
  active: (
    color: variables.color("gray", 600),
    border-color: variables.color("gray", 600),
  ),
  disabled: map.deep-map-merge(variables.color(disabled), (
    background: deep-map-get(variables.color(disabled), border-color)
  )),
);

$btn-danger: (
  default: (
    color: variables.color("white"),
    background: variables.color(danger, color),
    border-color: variables.color(danger, color)
  ),
  hover: (
    color: variables.color("white"),
    background: variables.color(danger, hover, color),
    border-color: variables.color(danger, hover, color)
  ),
  active: (
    color: variables.color("white"),
    background: variables.color(danger, active, color),
    border-color: variables.color(danger, hover, color)
  ),
  disabled: deep-map-get($btn-secondary, disabled)
);

$btn-approve: (
  default: (
    color: variables.color("white"),
    background: variables.color(success, color),
    border-color: variables.color(success, color)
  ),
  hover: (
    color: variables.color("white"),
    background-color: variables.color("green", 500),
    border-color: variables.color("green", 500),
  ),
  active: (
    color: variables.color("white"),
    background-color: variables.color("green", 900),
    border-color: variables.color("green", 900),
  ),
  disabled: deep-map-get($btn-secondary, disabled)
);

$btn-approve-small: map.deep-map-merge($btn-approve, (default: (
  color: variables.color(success, color),
  background: variables.color("white"),
  border-color: variables.color(success, color)
)));

$btn-reject: (
  default: (
    color: variables.color(danger, color),
    background: variables.color("white"),
    border-color: variables.color(danger, color)
  ),
  hover: (
    color: variables.color("white"),
    background-color: variables.color("red", 500),
    border-color: variables.color("red", 500),
  ),
  active: (
    color: variables.color("white"),
    background-color: variables.color("red", 900),
    border-color: variables.color("red", 900),
  ),
  disabled: map.deep-map-merge(variables.color(disabled), (
    background: variables.color("gray", 300),
  ))
);

$btn-review: (
  default: (
    color: variables.color(link, color),
    background: variables.color("white"),
    border-color: variables.color(link, color),
  ),
  hover: (
    color: variables.color(link, hover, color),
    border-color: variables.color(link, hover, color)
  ),
  active: (
    color: variables.color(link, active, color),
    border-color: variables.color(link, active, color)
  ),
  disabled: map.deep-map-merge(variables.color(disabled), (
    background: variables.color("gray", 300),
  ))
);

.btn {
  @extend %transition-base;
  @extend %font-sans-normal-700;
  @extend %border-radius-1;
  @extend %box-shadow-1;

  align-items: center;
  background: transparent;
  border: variables.$btn-border-width solid transparent;
  cursor: pointer;
  display: inline-block;
  margin-top: 1rem;
  outline: none;
  padding: variables.$btn-xs-padding;
  text-transform: uppercase;
  @include text.text-sizing(variables.$btn-font-size, variables.$btn-line-height);

  &:hover {
    @extend %box-shadow-3;
    transform: scale(variables.$scale);
  }

  &.btn-small {
    @extend %font-sans-normal-500;

    border-width: variables.$btn-small-border-width;
    padding: variables.$btn-small-xs-padding;
    text-transform: none;
    @include text.text-sizing(variables.$btn-small-font-size, variables.$btn-small-line-height);

    @include mq.mq($from: md) {
      padding: variables.$btn-small-md-padding;
      @include text.text-sizing(variables.$btn-font-size, variables.$btn-line-height);
    }

    .icon {
      margin-right: 0.25rem;
    }
  }

  &.btn-no-transform {
    &:hover {
      transform: none;
    }
  }

  &.t-transform-none {
    text-transform: none;
  }

  &.is-loading {
    opacity: 0.5;
  }

  &.btn-social {
    @extend %icon-circle;
    background-color: variables.color(link, bg);
    margin-right: 1rem;
    margin-top: 0.5rem;

    &:last-child {
      margin-right: 0;
    }

    &:active,
    &:hover {
      .icon {
        color: variables.color("white"); // stylelint-disable-line
      }
    }

    &:active {
      background-color: variables.color(link, active, color);
    }

    &:hover {
      background-color: variables.color(link, hover, color);
    }

    svg.icon {
      color: variables.color(link, color);
    }
  }

  &[disabled]:not(.nothing),
  &[data-disabled]:not(.nothing) { // use :not(.nothing) to be more specific than other classes
    @extend %btn-disabled;
    @extend %box-shadow-1;
    pointer-events: none;
    transform: none;
    transition: none;
  }

  @include mq.mq($from: md) {
    padding: variables.$btn-md-padding;
  }
}

.btn-primary {
  @include btn-gen($btn-primary);

  @include inverted.inverted {
    @include btn-gen($btn-primary-inverted);
  }
}

.btn-secondary {
  @include btn-gen($btn-secondary);

  @include inverted.inverted {
    @include btn-gen($btn-secondary-inverted);
  }
}

.btn-danger-secondary {
  @include btn-gen($btn-danger-secondary);
}

.btn-gray {
  @include btn-gen($btn-gray);
}

.btn-danger {
  @include btn-gen($btn-danger);
}

.btn-approve {
  @include btn-gen($btn-approve);
  &.btn-small:not(.green) {
    @include btn-gen($btn-approve-small);
  }
}

.btn-stacked {
  @extend %font-sans-normal-500;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  text-transform: none;
  @include mq.mq($from: md) {
    font-size: 1rem;
  }
  .stacked-icon {
    margin-bottom: 0.5rem;
    .icon {
      height: 2rem;
      width: 2rem;
      @include mq.mq($from: sm) {
        height: 2.5rem;
        width: 2.5rem;
      }
    }
  }
}

.btn-reject {
  @include btn-gen($btn-reject);
}

.btn-review {
  @include btn-gen($btn-review);
  &.chevron-open {
    border-color: deep-map-get($btn-review, active, border-color);
    color: deep-map-get($btn-review, active, color);
  }
}

%btn-no-chrome {
  appearance: none;
  background: transparent;
  outline: none;
  padding: 0;
}

%btn-no-chrome-border {
  border-color: transparent;
  border-left: 0;
  border-right: 0;
  border-top: 0;
}

%btn-disabled {
  cursor: default !important;
  :focus {
    outline: 0 !important;
  }
}

%btn-disabled-no-transform {
  @extend %btn-disabled;
  transform: none !important;
}

%btn-link-base {
  @extend %anchor-focus-styles-focus-visible;
  &.disabled,
  &[disabled],
  &[data-disabled] {
    @extend %btn-disabled-no-transform;
    @extend %anchor-no-decoration;

    color: deep-map-get(variables.color(disabled), color);

    @include inverted.inverted {
      color: variables.color("gray", 400);
    }
  }
  &.disabled-allow-transform {
    @extend %btn-disabled;
    @extend %anchor-no-decoration;

    color: deep-map-get(variables.color(disabled), color);
  }

  &.input-aligned {
    margin-top: variables.$label-height;
    min-height: variables.$input-height;

    &.no-min-height {
      min-height: unset;
    }

    &.wrap-button {
      margin-bottom: variables.$input-margin-bottom;
      margin-top: 0;
      min-height: unset;
      @include mq.mq($from: md) {
        margin-top: variables.$label-height;
        min-height: variables.$input-height;
      }
    }
  }
}

.btn-link {
  @extend %anchor;
  @extend %btn-no-chrome;
  @extend %btn-no-chrome-border;
  @extend %anchor-state-styles;
  @extend %btn-link-base;

  @include inverted.inverted {
    @extend %anchor-state-styles-white;
  }
}

.btn-link-multiline {
  @extend %btn-link-base;
  @extend %btn-no-chrome;

  border: none;

  span {
    @extend %anchor;
    @extend %btn-no-chrome-border;
    @extend %anchor-state-styles;
    @include inverted.inverted {
      @extend %anchor-state-styles-white;
    }
  }
}

.btn-close {
  @extend %btn-no-chrome;
  @extend %btn-no-chrome-border;
  @extend %transition-base;
  @extend %anchor-no-decoration;
  @extend %anchor-state-styles;

  border-radius: 0.25rem;
  height: 3rem;
  width: 3rem;

  @include text.text-sizing(2.5rem, 1rem);
  @include inverted.inverted {
    @extend %anchor-state-styles-white;

    &:hover {
      text-shadow: 0 0 0.1875rem variables.color("white");
    }
  }

  &:hover {
    text-shadow: 0 0 0.1875rem variables.color("black");
  }

  @include mq.mq($from: md) {
    @include text.text-sizing(3rem, 1rem);
  }
}

.icon-button {
  @extend %anchor-no-decoration;
  @extend %transition-base;
  @extend %anchor-focus-styles-focus-visible;

  appearance: none;
  background: transparent;
  border: none;
  border-color: transparent;
  cursor: pointer;
  outline: none;
  padding: 0;

  @include inverted.inverted {
    @extend %anchor-state-styles-white;
  }

  &:hover {
    .icon {
      opacity: 1;
    }
  }

  &[disabled],
  &[data-disabled] {
    @extend %btn-disabled-no-transform;
    .icon {
      color: deep-map-get(variables.color(disabled), color) !important;
    }
  }

  .icon {
    margin: auto;
  }

  .icon-close-x {
    opacity: 0.8;
  }
}

.btn-arrow {
  @extend %btn-no-chrome;
  @extend %btn-no-chrome-border;
  @extend %anchor-state-styles;
  @extend %anchor-no-decoration;
  @extend %transition-base;

  padding: variables.$btn-arrow-padding;

  @include inverted.inverted {
    @extend %anchor-state-styles-white;
  }

  &:focus {
    @extend %focus-shadow;
  }

  &[disabled],
  &[data-disabled] {
    @extend %btn-disabled-no-transform;
  }
}

$btn-callout-action-size-bump: sm;
$btn-callout-action-height: 4.5rem;
$btn-callout-action-width: 19rem;

@mixin btn-callout-default-styles {
  height: $btn-callout-action-height;
  padding: 2.875rem 0.125rem 0.125rem 0.125rem;
  text-align: center;
  width: 6rem;

  .callout-icon {
    left: 1.625rem;
    .loading-state {
      .loading-message {
        margin-left: 0;
        .loader {
          margin-right: 0;
        }
      }
    }
    .icon,
    .loader  {
      height: 2.5rem;
      width: 2.5rem;
    }
  }
  .callout-plus {
    $callout-plus-large-size: 1.5rem;
    border-radius: $callout-plus-large-size * 0.5;
    height: $callout-plus-large-size;
    left: 1.125rem;
    padding-top: 0.125rem;
    width: $callout-plus-large-size;

    .icon-plus {
      height: 1rem;
      margin: 0;
      width: 1rem;
    }
  }
}

%callout-icon-pos {
  @extend %transition-base;
  position: absolute;
}

.btn-callout {
  @extend .btn-secondary;
  @extend %font-sans-normal-500;
  display: block;
  margin: 0 0 variables.$btn-callout-action-mb;
  position: relative;
  text-transform: initial;
  @include btn-callout-default-styles;
  @include text.text-sizing(0.875rem, 1.25rem);

  &:hover
  {
    .callout-icon {
      color: variables.color("gray", 900);
    }

    .callout-plus {
      background-color: variables.color(link, hover, color);
    }
  }

  &:focus {
    @extend %focus-shadow;
  }

  &:active {
    .callout-plus {
      background-color: variables.color(link, active, color);
    }
  }

  &-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 -0.5rem;
    > * {
      display: flex;
      flex: 0 1 auto;
      padding: 0 0.5rem;
    }
  }

  .callout-icon {
    @extend %callout-icon-pos;
    top: 0.375rem;
    .loader,
    .icon {
      height: 2.5rem;
      margin: 0;
      width: 2.5rem;
    }
    .icon {
      color: variables.color("gray", 700);
    }
  }

  .callout-plus {
    @extend %callout-icon-pos;
    background-color: variables.color(link, color);
    border: variables.$btn-border-width solid variables.color("white");
    top: 0.375rem;

    .icon-plus {
      color: variables.color("white"); // stylelint-disable-line declaration-property-value-disallowed-list
      left: 0.125rem;
      position: absolute;
      top: 0.125rem;
      vertical-align: initial;
    }
  }

  // Need to target a span here because adding a class with the only entry of
  // @extend %font-sans-normal-500; is not enough for a new _buttons.ts to get
  // updated. Also, .btn properties are overwriting here when they shouldn't.
  span {
    @extend %font-sans-normal-500;
  }
}

%btn-callout {
  height: 3rem;
  margin-bottom: 1rem;
  padding: 0.75rem 0.75rem 0.75rem 3.25rem;
  position: relative;
  text-align: left;
  text-transform: initial;
  width: 100%;

  @include mq.mq($from: sm) {
    margin-bottom: 0;

    &:not(:last-of-type) {
      margin-right: 1rem;
    }
  }

  .callout-icon {
    left: 0.875rem;
    .loader,
    .icon {
      height: 2rem;
      width: 2rem;
    }
  }

  .callout-plus {
    $callout-plus-size: 1.25rem;
    border-radius: $callout-plus-size * 0.5;
    height: $callout-plus-size;
    left: 0.375rem;
    width: $callout-plus-size;
    .icon-plus {
      height: 0.75rem;
      width: 0.75rem;
    }
  }
}

%btn-callout-full-xs {
  .btn-callout {
    @extend %btn-callout;

    @include mq.mq($from: $btn-callout-action-size-bump) { // stylelint-disable-line order/order
      @include btn-callout-default-styles;
    }
  }
}

.dashboard-callouts {
  @extend %btn-callout-full-xs;
  display: flex;
  flex-direction: column;

  @include mq.mq($from: $btn-callout-action-size-bump) {
    flex-direction: row;
  }
}

.callouts-row {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: 100%;
  @include mq.mq($from: $btn-callout-action-size-bump) {
    flex-direction: row;
  }
}

$cropper-input-height: 8.5rem;
.btn-crop {
  align-items: center;
  color: variables.color(link, color);
  display: flex;
  flex-direction: column;
  height: $cropper-input-height;
  justify-content: center;
  margin-bottom: 0;
  padding: 0;
  width: 100%;
  &.btn:hover {
    transform: initial;
  }
  .icon-container {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .callout-plus {
    left: auto;
    position: relative;
    top: auto;
  }
  .icon-image {
    font-size: 1.25rem;
  }
  @at-root .has-danger & {
    color: variables.color(link, color);
  }
}

$content-height-action-size-bump: 3rem;
$content-height: 2.5rem;
$content-width-action-size-bump: 2.5rem;
$content-width: 2.125rem;

.btn-action-base {
  align-items: center;
  background-color: variables.color("white");
  border-color: variables.color("gray", 300);
  color: var(--accent-2-700);
  display: flex;
  flex: 1 1 100%;
  justify-content: space-between;
  margin: 0;

  &:hover {
    border-color: variables.color(link, hover, color);
  }

  &.no-hover:hover {
    @extend %box-shadow-1;
    border-color: variables.color("gray", 300);
    cursor: default;
    transform: none;

    .content {
      cursor: default;
    }
  }

  .btn,
  button {
    border-bottom: none; // remove ButtonLink bottom border
    &:hover,
    &:focus,
    &:active {
      border-bottom: none;
    }
  }

  .btn-link {
    @extend %font-sans-normal-500;

    align-items: center;
    color: var(--accent-2-700);
    display: flex;

    &.action-type .icon {
      @extend %transition-base;
      color: variables.color(link, color);
      margin: 0;
      padding-left: 0.5rem;

      &:hover {
        color: variables.color(link, hover, color);
      }

      &:active {
        color: variables.color(link, active, color);
      }

      &.icon-close-x {
        color: variables.color("gray", 400);
        &:hover {
          color: variables.color(danger, hover, color);
        }
        &:active {
          color: variables.color(danger, active, color);
        }
      }

      @include mq.mq($from: $btn-callout-action-size-bump) {
        padding-left: 0.75rem;
      }
    }


    .content-label {
      flex-grow: 1;
      padding-right: 0.75rem;
      text-align: left;

      .content-name {
        @extend %font-sans-normal-500;
        @extend .truncate;
      }
    }
  }
}

.btn-action-short {
  @extend .btn-action-base;

  .btn-link {
    &.action-type {
      align-items: center;
      display: flex;
      height: 1rem;

      .icon {
        border-left: variables.$btn-border-width solid variables.color("gray", 300);
        height: 1.5rem;
        width: 2.25rem;
      }
    }
    &.content {
      height: 1rem;
      .content-label {
        .content-name {
          @extend .truncate;
          @include truncate.truncate-lines(1);
          @include text.text-sizing(1rem, 1.25rem);
        }
      }
    }
  }
}


.btn-action-tall {
  @extend .btn-action-base;
  height: 4rem;
  padding: 0.5rem;

  @include mq.mq($from: $btn-callout-action-size-bump) {
    height: $btn-callout-action-height;
    padding: 0.75rem;
    width: $btn-callout-action-width;
  }

  .btn-link {
    &.action-type {
      border-left: variables.$btn-border-width solid variables.color("gray", 300);
      display: block;
      height: 100%;
      .icon {
        height: 2rem;
        width: 2rem;
      }
    }

    .content-type {
      flex-shrink: 0;
      margin-right: 1rem;
      .icon  {
        height: $content-height;
        width: $content-width;

        @include mq.mq($from: $btn-callout-action-size-bump) {
          height: $content-height-action-size-bump;
          width: $content-width-action-size-bump;
        }
      }
      img {
        height: $content-height;
        object-fit: cover;
        object-position: 50% 0%;
        width: $content-width;

        @include mq.mq($from: $btn-callout-action-size-bump) {
          height: $content-height-action-size-bump;
          width: $content-width-action-size-bump;
        }
      }
    }

    .content-label {
      .content-name {
        @include text.text-sizing(0.875rem, 1rem);
        @include truncate.truncate-lines(2);
        &.has-meta {
          @include truncate.truncate-lines(1);
        }
        @include mq.mq($from: $btn-callout-action-size-bump) {
          font-size: 1rem;
        }
      }

      .content-meta {
        @extend %font-sans-normal-400;
        -webkit-box-orient: vertical;
        color: variables.color("gray", 600);
        display: -webkit-box;
        -webkit-line-clamp: 1;
        overflow: hidden;
        @include text.text-sizing(0.75rem, 1.125rem);

        @include mq.mq($from: $btn-callout-action-size-bump) {
          font-size: 0.875rem;
        }
      }
    }
  }
}

.btn-circle {

  @extend %box-shadow-2;

  border: 0;
  border-radius: variables.$btn-circle-diameter * 0.5;
  height: variables.$btn-circle-diameter;
  padding: 1rem;
  width: variables.$btn-circle-diameter;

  .icon {
    height: 1.5rem;
    margin: 0;
    width: 1.5rem;
  }
}

.icon.external-link-icon {
  font-size: 0.625rem;
  height: 1.5em;
  margin: -0.4375em 0.1875em 0 0.0625em;
  vertical-align: middle;
  width: 1.5em;

  @include mq.mq($from: md) {
    font-size: 0.75rem;
  }
}

.buttons-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  &.divider {
    border-top: borders.$border-200;
    margin-top: 1rem;
  }
  .form-section + & {
    margin-top: 0;
  }
  .btn:not(:last-child) {
    margin-right: 1rem;
  }
}

.inline-edit-buttons {
  $action-line-height: 1.5rem;

  .form-input,
  .text-editor-skeleton {
    margin-bottom: 0;
  }

  .list-select {
    margin-bottom: 1rem;
  }
  .actions {
    @extend %font-sans-normal-500;
    @extend %transition-base;
    font-size: 1rem;
    height: $action-line-height;
    line-height: $action-line-height;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transform: translateY(-$action-line-height);
    &.show {
      opacity: 1;
      pointer-events: auto;
      transform: translateY(0.5rem);
    }
    button {
      &:active,
      &:focus,
      &:hover {
        border-bottom-color: transparent;
      }
      &:focus {
        @extend %focus-shadow;
      }
    }
    .discard {
      color: variables.color("red", 700);
      &:hover {
        color: variables.color("red", 500);
      }
      &:active {
        color: variables.color("red", 900);
      }
    }
    .save {
      margin-right: 1rem;
    }
    svg {
      margin-right: 0.25rem;
    }
  }
}

// Two Color Icon Colors and Classes

$icon-two-color-primary: (primary: variables.color(link, color));

$icon-two-color-primary-hover: (primary: variables.color(link, hover, color));

$icon-two-color-primary-active: (primary: variables.color(link, active, color));

$icon-two-color-danger-hover: (primary: variables.color(danger, hover, color));

$icon-two-color-danger-active: (primary: variables.color(danger, active, color));

$icon-two-color-disabled: (
  primary: variables.color("gray", 500),
  background: variables.color("gray", 200),
);

$icon-two-color-black: (primary: variables.color("black"));

$icon-two-color-teal: (primary: variables.color("teal", 700));

%primary-2-color {
  @include icon-colors($icon-two-color-primary);
}

%primary-2-color-active {
  @include icon-colors($icon-two-color-primary-active);
}

%black-2-color {
  @include icon-colors($icon-two-color-black);
}

%active-2-color {
  @include icon-colors($icon-two-color-primary-active);
}

%teal-2-color {
  @include icon-colors($icon-two-color-teal);
}

%disabled-2-color {
  @include icon-colors($icon-two-color-disabled);
}

.primary-2-color-icon {
  @extend %primary-2-color;
  &:active {
    @extend %primary-2-color-active;
  }
}

.icon-2-color-teal {
  @extend %teal-2-color;
}

.icon-2-color-disabled {
  @extend %disabled-2-color;
}
