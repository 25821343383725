@use "sass:map";
@use "../functions/breakpoints";
@use "../functions/shadows";
@use "../variables";

@each $bp in map.keys(variables.$breakpoints) {
  @for $i from 1 through 4 {
    @include breakpoints.bp-up($bp) {
      @include breakpoints.bp-selector("%", "box-shadow", $bp, $i) {
        @include shadows.box-shadow($i);
      }
      @include breakpoints.bp-selector("%", "text-shadow", $bp, $i) {
        @include shadows.text-shadow($i);
      }
      @include breakpoints.bp-selector("%", "filter-drop-shadow", $bp, $i) {
        @include shadows.filter-drop-shadow($i);
      }
    }
  }
}

@for $i from 1 through 4 {
  .box-shadow-#{$i} {
    @extend %box-shadow-#{$i};
  }

  .text-shadow-#{$i} {
    @extend %text-shadow-#{$i};
  }

  .filter-drop-shadow-#{$i} {
    @extend %filter-drop-shadow-#{$i};
  }
}

.no-box-shadow {
  box-shadow: none !important;
}

.no-text-shadow {
  text-shadow: none !important;
}

.no-filter-drop-shadow {
  filter: none !important;
}

%filter-drop-shadow-icon-white-1 {
  filter: drop-shadow(0 1px 3px rgba(0,0,0,0.24));
}

%filter-drop-shadow-icon-white-3 {
  filter: drop-shadow(0 1px 3px rgba(0,0,0,0.36));
}

%focus-shadow {
  box-shadow: 0 0 0.5rem var(--accent-1-700);
  outline: 0;
}

%focus-drop-shadow  {
  filter: drop-shadow(0 0 0.5rem var(--accent-1-700));
}
