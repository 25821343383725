@use "sass:map";
@use "../functions/breakpoints";
@use "../variables";

$flex-directions: (
  row: row,
  row-reverse: row-reverse,
  col: column,
  col-reverse: column-reverse
);

$flex-alignments: (
  start: flex-start,
  end: flex-end,
  center: center,
  baseline: baseline,
  stretch: stretch
);

$flex-justifications: (
  start: flex-start,
  end: flex-end,
  center: center,
  between: space-between,
  around: space-around,
  evenly: space-evenly
);

$flex-grow: (
  grow: 1,
  no-grow: 0,
);

$flex-shrink: (
  shrink: 1,
  no-shrink: 0
);

$flex-basis: (
  "auto": auto,
  "0": 0%,
  "100": 100%
);

$order: (
  "-1": -1,
  "0": 0,
  "1": 1,
  "2": 2,
  "3": 3
);

$flex-wraps: (
  wrap: wrap,
  wrap-reverse: wrap-reverse,
  nowrap: nowrap
);

@mixin flex-classes($map, $class-prefix, $prop) {
  @each $bp in map.keys(variables.$breakpoints) {
    @each $class-val, $val in $map {
      @include breakpoints.bp-up($bp) {
        @include breakpoints.bp-class($class-prefix, $bp, $class-val) {
          #{$prop}: #{$val} !important;
        }
      }
    }
  }
}

@include flex-classes($flex-directions, flex, flex-direction);
@include flex-classes($flex-alignments, align-items, align-items);
@include flex-classes($flex-alignments, align-self, align-self);
@include flex-classes($flex-justifications, justify-content, justify-content);
@include flex-classes($flex-grow, flex, flex-grow);
@include flex-classes($flex-shrink, flex, flex-shrink);
@include flex-classes($flex-basis, flex, flex-basis);
@include flex-classes($flex-wraps, flex, flex-wrap);
@include flex-classes($order, order, order);

@each $n, $v in variables.$spacers {
  .cg-#{$n} {
    column-gap: $v !important;
  }
}

@each $n, $v in variables.$spacers {
  .rg-#{$n} {
    row-gap: $v !important;
  }
}

@each $n, $v in variables.$spacers {
  .gap-#{$n} {
    gap: $v !important;
  }
}
