@use "../../variables";
@use "../../mq";
@use "../../typography/a";
@use "../../typography/fonts";

.bl-tooltip-content-container {
  display: flex;
  flex-direction: column;
  font-size: variables.$tooltip-font-size;
  height: 100%;
  letter-spacing: initial;
  line-height: variables.$tooltip-line-height;
  min-width: 14rem;
  padding: variables.$tooltip-container-padding;
  position: relative;
  text-align: left;
  text-transform: initial;
  white-space: normal;
  width: 100%;

  &.no-min-width {
    min-width: 0;
  }


  .bl-tooltip {
    &-header {
      align-items: baseline;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .title {
        @extend %font-sans-normal-700;

        color: variables.color("black");
        font-size: variables.$tooltip-font-size;
        margin-top: 0;

        // Have to have the muted here because of the header color scope
        &.muted {
          color: variables.color("gray", 600) !important;
        }
      }

      .tooltip-action {
        // So that it's always on the right-hand side even when the title
        // is not present
        margin-left: auto;

        &:disabled {
          color: variables.color("gray", 600);
          cursor: not-allowed;
          text-decoration: none;
        }
      }
    }

    &-content {
      @extend %font-sans-normal-400;
      font-size: variables.$tooltip-font-size;
      line-height: variables.$tooltip-line-height;
      margin: 0;

      &:last-child {
        margin-bottom: 0;
      }

      &.emphasized {
        @extend %font-sans-italic-400;
      }

      a {
        @extend %anchor-state-styles;
      }

      *:last-child {
        margin-bottom: 0;
      }
    }

    &-list-content {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding-left: 0;

      .tooltip-list-item {
        @extend %font-sans-normal-400;
        border-bottom: none;
        display: inline-block;
        margin-bottom: 0.5rem;
        margin-left: 0;
        padding-left: 0;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }


}

/* Useful for when you need to wrap a Button-like component with
our 'Tooltip' component, since the Tooltip wrapper component interferes
with the flexbox behavior of the button container. */
.tooltip-btn-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: max-content;

  @include mq.mq($from: md) {
    flex-direction: row;

    > .btn {
      width: initial;
    }
  }

  > .btn {
    width: 100%;
  }
}
