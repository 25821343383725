@use "../functions/spacing";

@include spacing.spacing(m, margin);
@include spacing.spacing(p, padding);

.emulated-flex-gap {
  --gap: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
  width: calc(100% + var(--gap));
}

.emulated-flex-gap > * {
  margin: var(--gap) 0 0 var(--gap) !important;
}
