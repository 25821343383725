@use "../mq";

.institution-section {
  .form-input[role="group"] {
    display: grid;
    margin-bottom: 0;

    @include mq.mq($from: md) {
      grid-template-columns: 1fr 1fr;
    }
    @include mq.mq($from: lg) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  .custom-indicator {
    margin-bottom: 1rem;
    margin-top: 0;
  }
}
