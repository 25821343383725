@use "../mq";

.not-found-content {
  margin: 0 1rem;
  width: 100%;
  @include mq.mq($from: lg){
    margin: 0;
    width: 50%;
  }
}
