@use "sass:map";
@use "../mq";

@mixin bp-up($bp) {
  @if $bp == xs {
    @content;
  } @else {
    @include mq.mq($from: $bp) {
      @content;
    }
  }
}

@mixin bp-down($bp) {
  @include mq.mq($until: $bp) {
    @content;
  }
}

@function mk-bp-selector($prefix, $start, $bp, $end) {
  @return #{$prefix}#{$start}#{if($bp == xs, "", "-#{$bp}")}#{if($end == "", "", "-#{$end}")};
}

@mixin bp-selector($prefix, $start, $bp, $end) {
  #{mk-bp-selector($prefix, $start, $bp, $end)} {
    @content;
  }
}

@mixin bp-class($start, $bp, $end) {
  @include bp-selector(".", $start, $bp, $end) {
    @content;
  }
}

/**
 * Returns the value of the largest bp that is less than or equal to $bp
 *
 * Assumes the $map is in order from smallest to largest breakpoints
 */
@function bp-leq($map, $bp, $orElse) {
  $largest: xs;
  @each $b in map.keys($map) {
    @if($b == $bp) {
      $v: map.get($map, $largest);
      @return if($v, $v, $orElse);
    }
  }

  $v: map.get($map, $largest); //stylelint-disable-line scss/no-duplicate-dollar-variables
  @return if($v, $v, $orElse);
}

/**
 * Takes a property name and a map of breakpoints to property values, e.g.
 *   @include bp-prop(width, (xs: 1rem, md: 3rem))
 */
@mixin bp-prop($prop, $bp-values) {
  @each $bp, $value in $bp-values {
    @include bp-up($bp) {
      /* stylelint-disable-next-line scss/selector-no-redundant-nesting-selector */
      & { #{$prop}: #{$value}; }
    }
  }
}

/**
 * Takes a map of breakpoints to property keys and values, e.g.
 *   @include bp-props((
 *     xs: (
 *       width: 1rem,
 *       height: 1rem,
 *     ),
 *     md: (
 *       width: 3rem,
 *       height: 3rem,
 *     )
 *   ))
 */
@mixin bp-props($bp-props) {
  @each $bp, $props in $bp-props {
    @include bp-up($bp) {
      @each $prop, $value in $props {
        #{$prop}: #{$value};
      }
    }
  }
}
